<template>
  <div class="content page-box">
    <div class="list-title">
      <h4 class="title">合同变更</h4>
    </div>
    <section class="content-box">
      <el-form
        ref="agreeForm"
        :model="agreeForm"
        :rules="formRules"
        label-width="120px"
        size="small"
      >
        <!-- 合同变更信息 -->
        <div class="detail-card">
          <header class="card-header">
            <!-- <h4 class="second-title">合同变更信息</h4> -->
            <TitleBar
              title="合同变更信息"
              :innerBtnBox="true"
              :hasStatus="hasStatus"
            >
              <div slot="btnBox">
                <button class="btn btn-lucency" @click="goMain">
                  查看主合同
                </button>
                <button
                  class="btn btn-lucency"
                  @click="goApproveDetail"
                  v-if="showBtn"
                >
                  审批详情
                </button>
              </div>
            </TitleBar>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item label="合同变更类型:" prop="type">
                  <el-select
                    disabled
                    v-model="agreeForm.type"
                    style="width: 100%"
                  >
                    <template v-for="(item, index) of agreetypeList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="10"
              v-if="
                agreeForm.type === 'RELEASE_AGREEMENT' ||
                  agreeForm.type === 'SIDE_AGREEMENT'
              "
            >
              <el-col :span="6">
                <el-form-item label="主合同名称:" prop="contractName">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="主合同编号:" prop="contractNo">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractNo"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="主合同类型:" prop="contractType">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractType"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="补充协议经办人:" prop="operatorName">
                  <span slot="label" v-if="agreeForm.type === 'SIDE_AGREEMENT'"
                    >补充协议经办人:</span
                  >
                  <span
                    slot="label"
                    v-if="agreeForm.type === 'RELEASE_AGREEMENT'"
                  >
                    解除协议经办人:</span
                  >
                  <el-input
                    type="text"
                    size="small"
                    v-model="currentUserName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="agreeForm.type === 'SUBJECT_CHANGE'">
              <el-col :span="8">
                <el-form-item label="主合同名称:" prop="contractName">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="主合同编号:" prop="contractNo">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractNo"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="主合同类型:" prop="contractType">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractType"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 补充协议 -->
            <el-row :gutter="10" v-if="agreeForm.type == 'SIDE_AGREEMENT'">
              <el-col :span="6">
                <el-form-item label="补充协议名称:" prop="agreementName">
                  <el-input
                    type="text"
                    size="small"
                    maxlength="255"
                    v-model="agreeForm.agreementName"
                    @keydown.enter.prevent.native="handle"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="补充协议经办日期:" prop="modifyDate">
                  <el-date-picker
                    v-model="agreeForm.modifyDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    @change="agreeDateChange"
                  >
                  </el-date-picker>
                  <span class="date-tip" v-show="agree_dateTip"
                    >请注意：所选日期早于当前日期</span
                  >
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item class="label-style" prop="needPay">
                  <span slot="label">补充协议是否<br />需要付款: </span>
                  <el-radio-group
                    v-if="needPayBtnOption === 'N'"
                    v-model="agreeForm.needPay"
                    disabled
                  >
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                  <el-radio-group
                    v-if="needPayBtnOption === 'Y'"
                    v-model="agreeForm.needPay"
                  >
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <!-- 是否供方合同 -->
              <el-col :span="6" v-if="agreeForm.type === 'SIDE_AGREEMENT'">
                <el-form-item label="是否供方合同:" prop="supplyStoreFlag">
                  <el-radio-group
                    disabled
                    v-model="agreeForm.supplyStoreFlag"
                    @change="changeContractSourceAdd"
                  >
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="agreeForm.type === 'SUBJECT_CHANGE'">
              <el-col :span="8">
                <el-form-item label="补充协议经办人:" prop="operatorName">
                  <span slot="label" v-if="agreeForm.type === 'SUBJECT_CHANGE'"
                    >主体变更经办人:</span
                  >
                  <el-input
                    type="text"
                    size="small"
                    v-model="currentUserName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否供方合同:" prop="supplyStoreFlag">
                  <el-radio-group
                    disabled
                    v-model="agreeForm.supplyStoreFlag"
                    @change="changeContractSource"
                  >
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 主体变更 -->
            <el-row v-if="agreeForm.type == 'SUBJECT_CHANGE'">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="原我方签约主体:">
                    <el-input
                      type="text"
                      size="small"
                      v-model="conInfo.ourCompany"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="现我方签约主体:" prop="ourCompany">
                    <el-select
                      multiple
                      filterable
                      placeholder="请输入不少于4个字的主体名称进行查询"
                      remote
                      :remote-method="searchOurCompany"
                      v-model="agreeForm.ourCompany"
                      ref="ourCompany"
                      style="width: 100%"
                      @focus="clearOptions"
                    >
                      <template v-for="(item, index) of ourCompanyList">
                        <el-option
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="原对方签约主体:">
                    <el-input
                      type="text"
                      size="small"
                      v-model="conInfo.supplierCompany"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="现对方签约主体:" prop="supplierCompany">
                    <el-select
                      multiple
                      filterable
                      placeholder="请输入不少于4个字的主体名称进行查询"
                      remote
                      :remote-method="searchSupplierCompany"
                      ref="supplierCompany"
                      v-model="agreeForm.supplierCompany"
                      style="width: 100%"
                      @focus="clearOptions"
                    >
                      <div slot="" class="option-wrapper">
                        <template>
                          <el-option
                            v-for="(item, index) of supplierCompanyListNew"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          >
                            <p class="option-title">
                              <span class="option-name" v-maia-ellipsis>{{
                                item.name
                              }}</span>
                              <span :class="['tag', item.type]">{{
                                item.type === "T02"
                                  ? "被投方"
                                  : item.type === "T03"
                                  ? "合作方"
                                  : "供方"
                              }}</span>
                            </p>
                          </el-option>
                        </template>
                      </div>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
          </section>
        </div>

        <!-- 补充协议签约主体 -->
        <div class="detail-card" v-show="agreeForm.type === 'SIDE_AGREEMENT'">
          <header class="card-header">
            <h4 class="second-title addSub-title">补充协议签约主体</h4>
            <el-tooltip
              effect="dark"
              content="原始合同的签约主体不可修改，只能新增"
              placement="bottom-start"
              :offset="-6"
            >
              <img src="../assets/img/info.jpg" class="sub-icon" />
            </el-tooltip>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="addOurCompany" label="我方签约主体:">
                  <el-select
                    multiple
                    filterable
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-defaultSelect="[
                      agreeForm.addOurCompany,
                      addOurCompanyList_total
                    ]"
                    remote
                    :remote-method="searchAddOurCompany"
                    v-model="agreeForm.addOurCompany"
                    style="width: 100%"
                    ref="addOurCompany"
                    @focus="clearOptions"
                    @change="deleteDisable('1')"
                  >
                    <template v-for="(item, index) of addOurCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                        :disabled="item.disabled"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="对方签约主体:" prop="addSupplierCompany">
                  <el-select
                    multiple
                    filterable
                    remote
                    :remote-method="searchAddSupplierCompany"
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-defaultSelect="[
                      agreeForm.addSupplierCompany,
                      addSupplierCompanyList
                    ]"
                    v-model="agreeForm.addSupplierCompany"
                    style="width: 100%"
                    ref="addSupplierCompany"
                    @change="deleteDisable('2')"
                    @focus="clearOptions"
                  >
                    <div slot="" class="option-wrapper">
                      <template>
                        <el-option
                          v-for="(item, index) of addSupplierCompanyListNew"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                          :disabled="item.disabled"
                        >
                          <p class="option-title">
                            <span class="option-name" v-maia-ellipsis>{{
                              item.name
                            }}</span>
                            <span :class="['tag', item.type]">{{
                              item.type === "T02"
                                ? "被投方"
                                : item.type === "T03"
                                ? "合作方"
                                : "供方"
                            }}</span>
                          </p>
                        </el-option>
                      </template>
                    </div>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 解除协议签约主体 -->
        <div class="detail-card" v-if="agreeForm.type === 'RELEASE_AGREEMENT'">
          <header class="card-header describe">
            <h4 class="second-title">签约主体</h4>
          </header>
          <div class="body-wrapper">
            <div class="body-cards">
              <h2>我方签约主体</h2>
              <ul>
                <li
                  v-if="
                    !(
                      JCXYcompanyList.ourCompanyList &&
                      JCXYcompanyList.ourCompanyList.length
                    )
                  "
                >
                  -
                </li>
                <li
                  v-for="(item, index) in JCXYcompanyList.ourCompanyList"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="body-cards">
              <h2>对方签约主体</h2>
              <ul>
                <li
                  v-if="
                    !(
                      JCXYcompanyList.supplierCompanyList &&
                      JCXYcompanyList.supplierCompanyList.length
                    )
                  "
                >
                  -
                </li>
                <li
                  v-for="(item, index) in JCXYcompanyList.supplierCompanyList"
                  :key="index"
                >
                  {{ item.name }}
                  <!-- <span v-show="item.type === 'T04'">供方</span> -->
                  <span v-show="item.type === 'T04'" v-supplier>{{
                    item.supplierSourceName || "供方"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- 补充协议-招采、金额、费项 -->
        <div v-if="agreeForm.type == 'SIDE_AGREEMENT'">
          <!-- 补充协议招采信息 -->
          <div class="detail-card">
            <header class="card-header">
              <h4 class="second-title">补充协议招采信息</h4>
            </header>
            <section class="card-content">
              <el-row :gutter="10">
                <el-col :span="18">
                  <el-form-item label="招标/比价/直委:" prop="orderMsg">
                    <el-input
                      type="textarea"
                      size="small"
                      :autosize="{ minRows: 2, maxRows: 6 }"
                      maxlength="5000"
                      show-word-limit
                      placeholder="请输入内容"
                      v-model="agreeForm.orderMsg"
                      @keydown.enter.prevent.native="handle"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <UploadComponent
                v-model="forminingfileList"
                :fileUploadList="forminingfileList"
                :fileCategory="'RECRUIT'"
                :updateFileList=this.updateFileList
                :label="''"
                :btnName="'上传招采附件'"
                :limit="10"
                :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
              />
            </section>
          </div>
          <!-- 补充协议金额 -->
          <div class="detail-card" v-if="agreeForm.needPay === 'Y'">
            <header class="card-header">
              <h4 class="second-title">补充协议金额</h4>
            </header>
            <section class="card-content" style="margin-top:10px;">
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item
                    prop="agreeForm.agreementAmountType"
                    class="label-style"
                  >
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <div slot="content">
                          <p>
                            金额追加类：指补充协议的协议金额为在主协议基础上追加的金额
                          </p>
                          <p>
                            金额替代类：指补充协议的协议金额会替代主协议的金额
                          </p>
                          <p>
                            金额不变类：指补充协议无需额外增加金额，仍按主协议的金额付款
                          </p>
                        </div>
                        <img
                          src="../assets/img/information.png"
                          class="money-icon"
                          alt="i"
                        />
                      </el-tooltip>
                      补充协议金额类型:
                    </span>
                    <el-select
                      v-model="agreeForm.agreementAmountType"
                      placeholder="请选择类型"
                      style="width: 100%"
                    >
                      <el-option label="金额追加类" value="1"></el-option>
                      <el-option label="金额替代类" value="2"></el-option>
                      <el-option label="金额不变类" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10" v-if="agreeForm.agreementAmountType != 3">
                <el-col :span="6">
                  <el-form-item label="补充协议币种:" prop="currency">
                    <el-input
                      disabled
                      v-model="agreeForm.currency"
                      v-if="agreeForm.needPay == 'Y'"
                    />
                    <el-select
                      v-model="agreeForm.currency"
                      style="width: 100%"
                      :clearable="true"
                      v-else
                    >
                      <template v-for="(item, index) of currencyList">
                        <el-option
                          :key="index"
                          :label="item.value"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="amount" class="label-style">
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        content="指本次补充协议自身的签约金额"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <img
                          src="../assets/img/information.png"
                          class="money-icon"
                          alt=""
                        />
                      </el-tooltip>
                      本次补充协议金额(含税):
                    </span>
                    <el-input
                      class="amount-input"
                      placeholder="请输入补充协议金额"
                      v-model="agreeForm.amount"
                      @input="limitInput($event, 'amount')"
                      @keydown.enter.prevent.native="handle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    prop="agreementAfterAmountTax"
                    class="label-style"
                  >
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        content="指本次补充协议签署后，主协议累计的合同总金额，即合同最大可付款金额"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <img
                          src="../assets/img/information.png"
                          class="money-icon"
                          alt=""
                        />
                      </el-tooltip>
                      主协议累计总金额(含税):
                    </span>
                    <el-input
                      class="amount-input"
                      placeholder="请输入主协议累计总金额"
                      v-model="agreeForm.agreementAfterAmountTax"
                      disabled
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="发票类型:" prop="invoiceType">
                    <el-select
                      v-model="agreeForm.invoiceType"
                      style="width: 100%"
                      :popper-append-to-body="false"
                    >
                      <template v-for="(item, index) of invoiceTypeList">
                        <el-option
                          :key="index"
                          :label="item.label"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10" v-if="agreeForm.agreementAmountType != 3">
                <el-col :span="6">
                  <el-form-item label="可抵扣税率:" prop="deductibleTaxRate">
                    <el-input
                      class="amount-input"
                      placeholder="请输入可抵扣税率"
                      v-model="agreeForm.deductibleTaxRate"
                      @input="limitInput($event, 'deductibleTaxRate')"
                      @keydown.enter.prevent.native="handle"
                    >
                      <i
                        slot="suffix"
                        :style="{ color: '#999', paddingRight: 6 + 'px' }"
                      >
                        %
                      </i>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="可抵扣税额:" prop="deductibleTax">
                    <el-input
                      disabled
                      class="amount-input"
                      placeholder="-"
                      v-model="agreeForm.deductibleTax"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="exContractAmount" class="label-style">
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        content="指本次补充协议签署后，主协议累计的合同总金额（不含抵扣税）"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <img
                          src="../assets/img/information.png"
                          class="money-icon"
                          alt=""
                        />
                      </el-tooltip>
                      主协议累计总金额(不含抵扣税):
                    </span>
                    <el-input
                      class="amount-input"
                      v-model="agreeForm.agreementAfterAmountNoTax"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>
          <!-- 费项分摊 -->
          <div class="detail-card" v-if="agreeForm.needPay === 'Y'">
            <header class="card-header cost">
              <h4 class="second-title">费项分摊</h4>
              <button class="btn btn-primary" @click.prevent="addCostList">
                <i class="el-icon-circle-plus-outline iconStyle"></i>
                新增
              </button>
            </header>
            <section class="card-content">
              <el-table
                :data="agreeForm.costExpenseList"
                ref="costExpenseList"
                style="width: 100%"
                border
                fit
              >
                <div slot="empty" class="empty-box">
                  <button class="btn" @click.prevent="addCostList">
                    <i class="el-icon-circle-plus-outline"></i>
                  </button>
                  <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
                </div>
                <el-table-column label="责任人" prop="operatorNo">
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.' + scope.$index + '.operatorNo'"
                      :rules="costFormRules.operatorNo"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.operatorNo"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) of dutyPeoList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="费项" prop="costItemsId">
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.' + scope.$index + '.costItemsId'"
                      :rules="costFormRules.costItemsId"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsId"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) of costDetailList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.FK_KMBM"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="预算分摊部门" prop="costItemsDept">
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="
                        'costExpenseList.' + scope.$index + '.costItemsDept'
                      "
                      :rules="costFormRules.costItemsDept"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsDept"
                        size="small"
                      >
                        <el-option
                          v-for="(item, index) of costShareDepartList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.F_ID"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="分摊金额(含税)"
                  prop="amount"
                  align="right"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="'costExpenseList.' + scope.$index + '.amount'"
                      :rules="costFormRules.amount"
                    >
                      <el-input
                        size="small"
                        v-model="scope.row.amount"
                        class="amount-input"
                        @input="
                          () => {
                            scope.row.amount = limitInput(scope.row.amount);
                          }
                        "
                        @keydown.enter.prevent.native="handle"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="60px" align="left">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        class="operate-btn"
                        type="text"
                        @click="dealCost(scope.$index, scope.row)"
                      >
                        删除
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </section>
          </div>
        </div>

        <!-- 合同变更说明 -->
        <div class="detail-card">
          <header class="card-header">
            <h4 class="second-title" v-if="agreeForm.type == 'SIDE_AGREEMENT'">
              合同变更说明
            </h4>
            <h4 class="second-title" v-if="agreeForm.type == 'SUBJECT_CHANGE'">
              主体变更协议说明
            </h4>
            <h4
              class="second-title"
              v-if="agreeForm.type == 'RELEASE_AGREEMENT'"
            >
              解除协议说明
            </h4>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item label="说明信息:" prop="agreementNote">
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="agreeForm.agreementNote"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>

        <!-- 文档 -->
        <div class="detail-card no-bottom-border">
          <header class="card-header">
            <h4 class="second-title">文档</h4>
          </header>
          <section class="card-content">

            <UploadComponent
              v-model="fileList_normal"
              :label="label"
              :fileCategory="'CONTRACT'"
              :updateFileList=this.updateFileList
              :showRemark="true"
              :btnName="'上传文件'"
              :limit="10"
              :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
            />

          </section>
        </div>
      </el-form>
    </section>
    <footer class="footer">
      <div class="btnBox">
        <button class="btn btn-lucency" @click="deleteAgree">删除</button>
        <button
          class="btn btn-primary btn-temporary"
          @click="storage"
          :disabled="storageDisabled"
          :class="storageDisabled ? 'storage_disabled' : ''"
        >
          暂存
        </button>
        <button class="btn btn-primary" @click="approval">下一步</button>
      </div>
    </footer>
  </div>
</template>

<script>
import TitleBar from "@/components/common/TitleBar.vue";
import UploadComponent from "@/components/common/UploadComponent.vue"
import utils from "@/utils/index";
import getCurrentUser from "./mixins/getCurrentUser";

export default {
  name: "agreementEdit",
  components: {
    TitleBar,
    UploadComponent
  },
  mixins: [getCurrentUser],
  data() {
    //附件校验规则
    var contractFiles = (rule, value, callback) => {
      let index = this.fileList_normal.findIndex(v => v.status === "success");
      // console.log(index, ",,,,,")
      if (index == "-1") {
        callback(new Error("附件不能为空"));
      } else {
        callback();
      }
    };
    return {
      currentUserName: "",
      label: "附件",
      id: "", // 合同变更ID
      contractId: "", // 主合同ID
      pageType: "", // 页面类型（编辑/草稿）
      approvalUrl: "", // 审批详情页地址
      showBtn: false, // 是否显示审批详情按钮（只有编辑会显示，草稿不显示）
      addSupplierCompanyListNew: [],
      supplierCompanyListNew: [],
      // 编辑页会显示协议的状态
      hasStatus: {
        show: true,
        status: ""
      },
      subTips: false,
      storageDisabled: false, // 暂存按钮禁用
      // 主合同信息
      conInfo: {
        ourCompanyId: [],
        supplierCompanyId: []
      },
      // 表单数据
      agreeForm: {
        type: "",
        supplyStoreFlag: "1",
        agreementAmountType: ""
      },
      // 表单验证
      formRules: {
        type: [{ required: true, message: "请选择协议类型" }],
        agreementName: [{ required: true, message: "请输入合同变更名称" }],
        amount: [
          { required: true, message: "请根据所选类型输入对应的补充协议金额" }
        ],
        agreementNote: [{ required: true, message: "请输入补充说明" }],
        invoiceType: [{ required: true, message: "请选择发票类型" }],
        attachFileList: [{ required: true, validator: contractFiles }],
        supplyStoreFlag: [{ required: false, message: "请选择是否供方合同" }],
        ourCompany: [{ required: true, message: "请选择现我方签约主体" }],
        supplierCompany: [{ required: true, message: "请选择现对方签约主体" }]
      },
      // 费项的表单验证
      costFormRules: {
        operatorNo: [{ required: true, message: "请选择责任人" }],
        costItemsId: [{ required: true, message: "请选择费项" }],
        costItemsDept: [{ required: true, message: "请选择费项分摊部门" }],
        amount: [{ required: true, message: "请输入金额" }]
      },
      // 协议类型列表
      agreetypeList: [
        {
          label: "补充协议",
          value: "SIDE_AGREEMENT"
        },
        {
          label: "主体变更",
          value: "SUBJECT_CHANGE"
        },
        {
          label: "解除协议",
          value: "RELEASE_AGREEMENT"
        }
      ],

      // 主体变更-主体列表
      ourCompanyList: [],
      supplierCompanyList: [],

      // 补充协议-主体增加列表
      addOurCompanyList: [],
      addSupplierCompanyList: [],

      // 存储主体增加的 主体集合，用于搜索
      addOurCompanyList_total: [],
      // addSupplierCompanyList_total: [],

      forminingfileList: [], // 补充协议的招采附件
      fileList_normal: [], // 附件列表

      subList: [], // 所有主体

      // 币种列表
      currencyList: [],
      //发票类型列表
      invoiceTypeList: [],

      // 费项责任人选择列表
      dutyPeoList: [],
      // 费项明细选择列表
      costDetailList: [],
      // 费项预算分摊部门列表
      costShareDepartList: [],

      // 全局loading
      loading: null,

      rightNow: "", // 当前日期
      agree_dateTip: false,

      // 校验供方合同是否选择供方主体
      checkContractNext: true,
      supplierIdArray: [],
      addSupplierIdArray: [],
      needPayBtnOption: "",
      JCXYcompanyList: {},

      /**
       * 合同详情
       */
      responseContract: {}
    };
  },
  methods: {
    handle() {},
    // 获取所有供方主体id存为数组
    getSupplierIdArray() {
      this.supplierCompanyList.forEach(item => {
        if (item.type === "T04") {
          this.supplierIdArray.push(item.id);
        }
      });
    },
    getAddSupplierIdArray() {
      this.addSupplierCompanyList.forEach(item => {
        if (item.type === "T04") {
          this.addSupplierIdArray.push(item.id);
        }
      });
    },
    // 校验是否供方合同并做处理
    checkContractSource() {
      this.checkContractNext = true;
      if (
        this.agreeForm.supplyStoreFlag === "1" &&
        this.agreeForm.supplierCompany
      ) {
        this.agreeForm.supplierCompany.forEach(item => {
          if (this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false;
          }
        });
      }
      if (
        this.agreeForm.supplyStoreFlag === "1" &&
        this.agreeForm.addSupplierCompany
      ) {
        this.agreeForm.addSupplierCompany.forEach(item => {
          if (this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false;
          }
        });
      }
      if (this.agreeForm.supplyStoreFlag === "0") {
        this.checkContractNext = false;
      }
    },
    // 改变合同来源
    changeContractSourceAdd(val) {
      if (val === "0") {
        // this.addSupplierCompanyListNew = []
        this.addSupplierCompanyList.forEach(item => {
          if (item.type === "T02" || item.type === "T03") {
            this.addSupplierCompanyListNew.push(item);
          }
        });
        if (this.agreeForm.addSupplierCompany) {
          this.agreeForm.addSupplierCompany.forEach((items, index) => {
            if (
              this.addSupplierIdArray &&
              this.addSupplierIdArray.includes(items)
            ) {
              this.agreeForm.addSupplierCompany.splice(index, 1);
            }
          });
        }
      }
      //  else {
      //   this.addSupplierCompanyListNew = this.addSupplierCompanyList
      // }
    },
    changeContractSource(val) {
      if (val === "0") {
        // this.supplierCompanyListNew = []
        this.supplierCompanyList.forEach(item => {
          if (item.type === "T02" || item.type === "T03") {
            this.supplierCompanyListNew.push(item);
          }
        });
        if (this.agreeForm.supplierCompany) {
          this.agreeForm.supplierCompany.forEach((items, index) => {
            if (this.supplierIdArray && this.supplierIdArray.includes(items)) {
              this.agreeForm.supplierCompany.splice(index, 1);
            }
          });
        }
      }
      //  else {
      //   this.supplierCompanyListNew = this.supplierCompanyList
      // }
    },
    // 补充协议部分币种和金额是否必填
    addMoneyCheck(val) {
      if (val !== "Y") {
        this.$set(this.formRules, "invoiceType", [
          { required: false, message: "请选择发票类型" }
        ]);
      } else {
        this.$set(this.formRules, "invoiceType", [
          { required: true, message: "请选择发票类型" }
        ]);
      }
      if (val !== "Y" && !this.agreeForm.amount && !this.agreeForm.currency) {
        this.$set(this.formRules, "amount", [
          { required: false, message: "请根据所选类型输入对应的补充协议金额" }
        ]);
        this.$set(this.formRules, "currency", [
          { required: false, message: "请选择币种" }
        ]);
      } else if (
        val !== "Y" &&
        !this.agreeForm.amount &&
        this.agreeForm.currency
      ) {
        this.$set(this.formRules, "amount", [
          { required: true, message: "请根据所选类型输入对应的补充协议金额" }
        ]);
        this.$set(this.formRules, "currency", [
          { required: false, message: "请选择币种" }
        ]);
      } else {
        this.$set(this.formRules, "amount", [
          { required: true, message: "请根据所选类型输入对应的补充协议金额" }
        ]);
        this.$set(this.formRules, "currency", [
          { required: true, message: "请选择币种" }
        ]);
      }
    },
    // 禁止删除原信息
    deleteDisable(val) {
      let oldVal =
        val === "1"
          ? this.conInfo.ourCompanyId
          : this.conInfo.supplierCompanyId;
      let newVal =
        val === "1"
          ? this.agreeForm.addOurCompany
          : this.agreeForm.addSupplierCompany;
      // console.log(newVal.toString(), JSON.stringify(oldVal))
      if (!newVal.toString().includes(oldVal.toString())) {
        val === "1"
          ? (this.agreeForm.addOurCompany = oldVal)
          : (this.agreeForm.addSupplierCompany = oldVal);
        this.$message.error("原始合同的签约主体不可修改，只能新增");
      }
    },
    filterUploadFailsFiles(fileListFilter) {
      // 处理附件
      var _file = [];
      fileListFilter.forEach(item => {
        let index = _file.findIndex(v => v.uid === item.uid);
        if ("success" === item.status && "-1" == index) {
          if (item.partFiles) {
            item.partFiles = "";
          }
          _file = [{ ...item }, ..._file];
        }
      });
      return _file;
    },
    //从子组件获取附件列表
    uploadFile(params) {
      if (params.length > 0) {
        this.$refs.attach?.$refs.file_rule?.clearValidate();
      }
      params.forEach(item => {
        item.fileCategory = "CONTRACT";
      });
      this.fileList_normal = params;
    },
    //招采类附件列表
    uploadForminingFileAgree(params) {
      params.forEach(item => {
        item.fileCategory = "RECRUIT";
      });
      this.forminingfileList = params;
    },

    updateFileList(file, fileCategory) {
      if (fileCategory == "CONTRACT") {
        let index = this.fileList_normal.findIndex(f => f.fileId === file.fileId)
        if(index !== -1) {
            return;
        }
        this.fileList_normal = this.fileList_normal.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.fileList_normal = [...this.fileList_normal, file]
      } else {
        let index = this.forminingfileList.findIndex(f => f.fileId == file.fileId)
        if(index !== -1) {
            return;
        }
        this.forminingfileList = this.forminingfileList.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.forminingfileList = [...this.forminingfileList, file]
      }
    },

    // 获取路径来源
    getUrl() {
      return new Promise(resolve => {
        let url = this.$route.path;
        let fdStart = url.indexOf("agreementedit");
        if (fdStart == 1) {
          // 编辑页
          this.pageType = "edit";
        } else {
          // 草稿页
          this.pageType = "draft";
        }
        let id = this.$route.params.id;
        this.id = id;
        resolve(id);
      });
    },
    //暂存
    storage() {
      if (
        this.agreeForm.type !== "SIDE_AGREEMENT" ||
        (this.agreeForm.type === "SIDE_AGREEMENT" &&
          this.agreeForm.agreementName.trim())
      ) {
        this.storageDisabled = true;
        this.saveAgree("draft").then(res => {
          this.$message({
            type: "success",
            message: "暂存成功",
            duration: 1000
          });
        });
      }
    },
    // 查看主合同
    goMain() {
      this.$open({
        name: "conDetail",
        path: `/condetail/${this.contractId}`,
        params: {
          id: this.contractId
        }
      });
    },
    // 查看审批详情
    goApproveDetail() {
      if (this.approvalUrl) {
        window.open(this.approvalUrl);
      } else {
        window.open(`/nobpm.html`);
      }
    },

    // 获取当前日期
    getRightNowDate() {
      let nowDate = new Date();
      let y = nowDate.getFullYear();
      let m = (nowDate.getMonth() + 1).toString();
      let d = nowDate.getDate().toString();

      m = m.length == 1 ? "0" + m : m;
      d = d.length == 1 ? "0" + d : d;

      this.rightNow = y + "-" + m + "-" + d;
      // this.agreeForm.contractDate = this.rightNow;
    },

    agreeDateChange(val) {
      if (val < this.rightNow) {
        this.agree_dateTip = true;
      } else {
        this.agree_dateTip = false;
      }
    },

    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value, name, this.agreeForm);
    },

    // 增加费项行
    addCostList() {
      this.agreeForm.costExpenseList.unshift({});
    },
    // 费项行数据处理
    dealCost(index, row) {
      // 删除当前这条数据
      this.agreeForm.costExpenseList.splice(index, 1);
    },

    // 删除
    deleteAgree() {
      this.$confirm("是否立即删除此合同，删除后，将无法恢复", "删除文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        lockScroll: false
      })
        .then(() => {
          let params = {
            id: this.id
          };
          this.$request
            .post("/contractAgreement/deleteAgreement", { params: params })
            .then(res => {
              if (res.code === "200" && res.data == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1000,
                  onClose: () => {
                    // 关闭当前页
                    window.opener = null;
                    window.open("about:blank", "_top").close();
                  }
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    //下一步按钮--获取表数据调用接口保存
    approval() {
      if (
        this.agreeForm.type === "SIDE_AGREEMENT" ||
        this.agreeForm.type === "SUBJECT_CHANGE"
      ) {
        this.checkContractSource();
        if (this.checkContractNext) {
          this.$message.error("供方合同请至少选择一个供方主体");
          return;
        }
      }

      this.$refs["agreeForm"].validate(valid => {
        if (valid) {
          // 打开全局loading
          this.loading = this.$loading({
            fullscreen: true,
            background: "rgba(255, 255, 255, 0.8)",
            text: "正在保存数据..."
          });
          // 发起接口调用
          this.saveAgree("agree")
            .then(res => {
              this.$message({
                type: "success",
                message: "保存成功，即将跳转审批页面",
                duration: 1500,
                onClose: () => {
                  // var pWin = window.open(res)
                  var pWin = window.open("_blank");
                  pWin.location = res;
                  if (pWin != null) {
                    // 关闭当前页
                    window.opener = null;
                    window.open("about:blank", "_top").close();
                  } else {
                    setTimeout(() => {
                      window.opener = null;
                      window.open("about:blank", "_top").close();
                    }, 500);
                  }
                }
              });
            })
            .catch(error => {
              // 关闭全局loading
              if (this.loading) {
                this.loading.close();
              }
              this.$message.error(error.message);
            });
        } else {
          this.$message({
            type: "error",
            message: "请填写必填项",
            duration: 1500
          });
        }
      });
    },

    // 保存调用接口
    saveAgree(type) {
      let params = {};
      let totalList = [];
      // 如果为主体变更，处理主体list结构 赋值给入参params
      if (this.agreeForm.type == "SUBJECT_CHANGE") {
        params = {};
        params.ourCompany = this.$formatSubList("ourCompany", this.agreeForm);
        params.supplierCompany = this.$formatSubList(
          "supplierCompany",
          this.agreeForm
        );
        params.supplyStoreFlag = this.agreeForm.supplyStoreFlag;
        totalList = [...this.fileList_normal];
      }
      // 如果为解除协议，入参只有必填项
      if (this.agreeForm.type == "RELEASE_AGREEMENT") {
        params = {};
        totalList = [...this.fileList_normal];
      }
      // 如果为合同变更，判断是否需要付款，处理入参
      if (this.agreeForm.type == "SIDE_AGREEMENT") {
        // 如果不需要付款，只需要传入名称和不需要付款字段
        if (this.agreeForm.needPay == "N") {
          params = {};
          params = Object.assign(this.agreeForm);
          totalList = [...this.fileList_normal, ...this.forminingfileList];
        } else {
          // 处理费项数据结构
          if (this.agreeForm.costExpenseList.length !== 0) {
            let costList = this.agreeForm.costExpenseList;
            costList.forEach(item => {
              this.costDetailList.forEach(it => {
                if (it.FK_KMBM === item.costItemsId) {
                  item.costItemsName = it.F_CAPTION;
                }
              });
              this.costShareDepartList.forEach(it => {
                if (it.F_ID === item.costItemsDept) {
                  item.costItemsDeptName = it.F_CAPTION;
                }
              });
            });
          }
          // this.forminingfileList = this.filterUploadFailsFiles(this.forminingfileList)

          // params.attachFileList = [...this.forminingfileList, ...this.fileList_normal]
          params = Object.assign(this.agreeForm); // 需要付款的传入所有表单内容
          totalList = [...this.fileList_normal, ...this.forminingfileList];
          // params.currency = this.agreeForm.currency
        }
        params.ourCompany = this.$formatSubList(
          "addOurCompany",
          this.agreeForm
        );

        params.supplierCompany = this.$formatSubList(
          "addSupplierCompany",
          this.agreeForm
        );
      }
      // 赋值通用信息
      params.id = this.id;
      params.contractId = this.contractId;
      params.type = this.agreeForm.type;
      params.agreementNote = this.agreeForm.agreementNote;

      params.attachFileList = [...this.filterUploadFailsFiles(totalList)];
      if (this.pageType === "draft" && type === "draft") {
        params.auditStatus = "draft";
      }
      if (this.pageType === "draft" && type === "agree") {
        params.auditStatus = "";
      }
      if (this.pageType === "edit") {
        params.auditStatus = this.agreeForm.auditStatus;
      }

      if (this.agreeForm.agreementAmountType == 3) {
        params = { ...params };
        params.amount = 0;
        params.invoiceType = "11";
      }

      /**
       * 暂存状态-需改为原始值
       * 主协议累计总金额(含税)
       * 主协议累计总金额(不含抵扣税)
       * [原因]编辑回显时，会重复计算
       */
      if (type === "draft") {
        params.agreementAfterAmountTax = this.responseContract.agreementAfterAmountTax;
        params.agreementAfterAmountNoTax = this.responseContract.agreementAfterAmountNoTax;
      }

      return new Promise(resolve => {
        this.$request
          .post("/contractAgreement/saveAgreement", { params })
          .then(res => {
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            this.storageDisabled = false;
            if (res.code === "200" && res.data) {
              resolve(res.data);
            }
          })
          .catch(error => {
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            this.$message.error(error);
          });
      });
    },

    // 获取协议信息
    getAgreeInfo(id) {
      let params = { id };
      this.$request
        .post("/contractAgreement/getAgreementInfo", { params })
        .then(res => {
          if (res.code === "200") {
            let data = res.data;
            this.responseContract = data || {};
            // 主合同信息
            this.conInfo = {
              contractName: data.contract.contractName,
              contractNo: data.contract.contractNo,
              contractType: data.contract.contractType,
              operatorName: data.contract.operatorName,
              ourCompany: utils.formatSubName(data.contract.ourCompany),
              supplierCompany: utils.formatSubName(
                data.contract.supplierCompany
              ),
              ourCompanyId: [...utils.formatSubId(data.contract.ourCompanyId)],
              supplierCompanyId: [
                ...utils.formatSubId(data.contract.supplierCompanyId)
              ],
              ourCompanyList: data.contract.ourCompanyList,
              supplierCompanyList: data.contract.supplierCompanyList
            };
            this.JCXYcompanyList = {
              supplierCompanyList: data.supplierCompanyList,
              ourCompanyList: data.ourCompanyList
            };
            // 是否供方合同
            let agreeForm = {};
            if (data.type === "RELEASE_AGREEMENT") {
              agreeForm = {};
            }

            if (data.type === "SUBJECT_CHANGE") {
              agreeForm = {
                ourCompany: [...utils.formatSubId(data.ourCompanyId)],
                supplierCompany: [...utils.formatSubId(data.supplierCompanyId)],
                supplyStoreFlag: data.supplyStoreFlag || "1"
              };

              this.changeContractSource(agreeForm.supplyStoreFlag);
              this.ourCompanyList = this.subList.T01.filter(element => {
                return agreeForm.ourCompany.indexOf(element.id) !== -1;
              });

              this.supplierCompanyListNew = this.subList.T02.filter(element => {
                return agreeForm.supplierCompany.indexOf(element.id) !== -1;
              });
            }

            if (data.type === "SIDE_AGREEMENT") {
              if (true) {
                agreeForm = {
                  amount: data.amount,
                  currency: data.currency,
                  invoiceType:
                    data.invoiceType === "11" ? "" : data.invoiceType,
                  deductibleTax: data.deductibleTax,
                  exContractAmount: data.exContractAmount,
                  aboveQuotaType: data.aboveQuotaType,
                  aboveQuotaAmount: data.aboveQuotaAmount,
                  aboveQuotaScale: data.aboveQuotaScale,
                  costExpenseList: data.costExpenseList,
                  orderMsg: data.orderMsg,
                  agreementAmountType: String(data.agreementAmountType),
                  agreementAfterAmountTax: data.agreementAfterAmountTax,
                  agreementAfterAmountNoTax: data.agreementAfterAmountNoTax,
                  supplyStoreFlag: data.supplyStoreFlag || "1"
                };

                data.deductibleTaxRate
                  ? (agreeForm.deductibleTaxRate = utils.BigNumberCacl(
                      data.deductibleTaxRate,
                      "100",
                      "*",
                      "noFixed"
                    ))
                  : (agreeForm.deductibleTaxRate = null);
                data.aboveQuotaScale
                  ? (agreeForm.aboveQuotaScale = utils.BigNumberCacl(
                      data.aboveQuotaScale,
                      "100",
                      "*",
                      "noFixed"
                    ))
                  : (agreeForm.aboveQuotaScale = null);
              }

              agreeForm.needPay = data.needPay;
              agreeForm.agreementName = data.agreementName;
              agreeForm.modifyDate = data.modifyDateStr;
              this.addMoneyCheck(agreeForm.needPay);
              agreeForm.addOurCompany = [
                ...utils.formatSubId(data.ourCompanyId)
              ];
              agreeForm.addSupplierCompany = [
                ...utils.formatSubId(data.supplierCompanyId)
              ];

              // this.addOurCompanyList = this.addOurCompanyList_total.filter(element=>{
              //   return agreeForm.addOurCompany.indexOf(element.id) !== -1
              // })
              this.addOurCompanyList = data.ourCompanyList || [];
              this.addSupplierCompanyListNew = data.supplierCompanyList || [];

              this.addOurCompanyList.forEach(item => {
                if (this.conInfo.ourCompanyId.indexOf(item.id) !== -1) {
                  item.disabled = true;
                }
              });

              // this.addSupplierCompanyList = (this.addSupplierCompanyList_total).filter(element=>{
              //   return agreeForm.addSupplierCompany.indexOf(element.id) !== -1
              // })

              this.addSupplierCompanyList.forEach(item => {
                if (this.conInfo.supplierCompanyId.indexOf(item.id) !== -1) {
                  item.disabled = true;
                }
              });
            }

            agreeForm.type = data.type;
            agreeForm.agreementNote = data.agreementNote;
            agreeForm.auditStatus = data.auditStatus;

            this.agreeForm = agreeForm;
            this.needPayBtnOption = data.contract.needPay;
            // 是否供方合同
            this.changeContractSourceAdd(this.agreeForm.supplyStoreFlag);

            this.hasStatus.status = data.auditStatus;
            this.contractId = data.contract.id;
            this.approvalUrl = data.viewUrl;

            //把回传的文件状态赋值为成功
            data.attachFileList.forEach((item, index) => {
              item.status = "success";
              item.uid = "a" + index;
            });

            let fileList = data.attachFileList;
            this.forminingfileList = fileList.filter(item => {
              return item.fileCategory == "RECRUIT";
            });
            this.fileList_normal = fileList.filter(item => {
              return item.fileCategory == "CONTRACT";
            });
          }
          if (this.loading) {
            this.loading.close();
          }
        })
        .catch(error => {
          // 关闭全局loading
          if (this.loading) {
            this.loading.close();
          }
          this.$message.error(error);
        });
    },

    // 获取主体列表
    getSubList() {
      return new Promise(resolve => {
        this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
          if (res.code === "200") {
            let data = res.data;
            this.subList = data;

            this.addOurCompanyList_total = JSON.parse(JSON.stringify(data.T01));
            this.addSupplierCompanyList_total = JSON.parse(
              JSON.stringify(data.T02)
            );
            this.addSupplierCompanyList = JSON.parse(JSON.stringify(data.T02));
            this.supplierCompanyList = JSON.parse(JSON.stringify(data.T02));
            // 获取供方主体id数组
            this.getSupplierIdArray();
            this.getAddSupplierIdArray();

            // 主体增加的情况下，默认值增加disabled属性
            this.addOurCompanyList_total.forEach(item => {
              if (this.conInfo.ourCompanyId.includes(item.id)) {
                item.disabled = true;
              }
            });
            this.addSupplierCompanyList_total.forEach(item => {
              if (this.conInfo.supplierCompanyId.includes(item.id)) {
                item.disabled = true;
              }
            });
            this.addSupplierCompanyList.forEach(item => {
              if (this.conInfo.supplierCompanyId.includes(item.id)) {
                item.disabled = true;
              }
            });
            resolve();
          }
        });
      });
    },

    // 获取费项列表责任人
    getCostDutePeo() {
      this.$request.post("/employee/queryEmployeeAll?scope=").then(res => {
        if (res.code === "200") {
          this.dutyPeoList = res.data;
        }
      });
    },

    // 获取费项预算部门
    getCostDep() {
      this.$request.post("/cost/queryCostItemDept").then(res => {
        if (res.code === "200") {
          this.costShareDepartList = res.data;
        }
      });
    },
    // 获取费项选择列表
    getCostList() {
      this.$request.post("/cost/queryCostItem").then(res => {
        if (res.code === "200") {
          this.costDetailList = res.data;
        }
      });
    },
    // 远程搜索我方主体
    searchOurCompany(keyWords) {
      this.ourCompanyList = this.$optionDataFilter(keyWords, this.subList.T01);
    },

    // 远程搜索对方主体
    searchSupplierCompany(keyWords) {
      this.supplierCompanyListNew = this.$optionDataFilter(
        keyWords,
        this.subList.T02
      );
    },

    // 远程搜索我方主体-补充协议主体增加
    searchAddOurCompany(keyWords) {
      this.addOurCompanyList = this.$optionDataFilter(
        keyWords,
        this.addOurCompanyList_total
      );
    },

    // 远程搜索对方主体-补充协议主体增加
    searchAddSupplierCompany(keyWords) {
      this.addSupplierCompanyListNew = this.$optionDataFilter(
        keyWords,
        this.addSupplierCompanyList_total
      );
    },

    clearOptions() {
      this.addOurCompanyList = [];
      this.supplierCompanyListNew = [];
      this.ourCompanyList = [];
      this.addSupplierCompanyListNew = [];
    }
  },
  computed: {
    listenChange() {
      const { amount, exContractAmount, deductibleTaxRate } = this.agreeForm;
      return { amount, exContractAmount, deductibleTaxRate, ...this.agreeForm };
    }
  },
  watch: {
    listenChange: {
      handler(val, oldval) {
        let { amount = 0 } = val || {};

        /**
         * 主协议累计总金额(含税)
         * 保留2位小数
         */
        this.agreeForm.agreementAfterAmountTax = utils.BigNumberCacl(
          val.agreementAfterAmountTax || 0,
          0,
          "-",
          2
        );

        /**
         * 金额不变类
         */
        if (val.agreementAmountType === "3") {
          amount = 0;
          this.agreeForm.amount = utils.BigNumberCacl(0, 0, "-", 2);
          this.agreeForm.agreementAfterAmountTax = utils.BigNumberCacl(
            this.responseContract.agreementAfterAmountTax || 0,
            amount || 0,
            "+",
            2
          );
        }

        /**
         * 金额追加类
         * 主协议累计总金额(含税)
         * = 主协议累计总金额（终表金额）+本次用户填写的补充协议金额(含税)
         */
        if (val.agreementAmountType === "1") {
          this.agreeForm.agreementAfterAmountTax = utils.BigNumberCacl(
            this.responseContract.agreementAfterAmountTax || 0,
            amount || 0,
            "+",
            2
          );
        }

        /**
         * 金额替代类
         * 主协议累计总金额(含税)
         * =本次用户填写的补充协议金额(含税)
         */
        if (val.agreementAmountType === "2") {
          this.agreeForm.agreementAfterAmountTax = utils.BigNumberCacl(
            amount || 0,
            0,
            "+",
            2
          );
        }

        // 合同变更金额,合同变更金额（不含税）,可抵扣税率
        if (val.agreementAfterAmountTax) {
          if (val.deductibleTaxRate) {
            let middle = utils.BigNumberCacl(
              this.agreeForm.agreementAfterAmountTax,
              1 + val.deductibleTaxRate / 100,
              "/"
            );
            let tax = utils.BigNumberCacl(
              val.deductibleTaxRate / 100,
              middle,
              "*"
            );
            this.agreeForm.deductibleTax = tax;

            this.agreeForm.exContractAmount = utils.BigNumberCacl(
              this.agreeForm.agreementAfterAmountTax,
              this.agreeForm.deductibleTax,
              "-"
            );
          } else {
            this.agreeForm.exContractAmount = utils.BigNumberCacl(
              this.agreeForm.agreementAfterAmountTax,
              "0",
              "-"
            );
            this.agreeForm.deductibleTax = null;
          }
        } else {
          this.agreeForm.exContractAmount = null;
          this.agreeForm.deductibleTax = null;
        }

        // 主协议累计总金额(不含抵扣税)
        if (![undefined, "", null].includes(amount)) {
          this.agreeForm.agreementAfterAmountNoTax = utils.BigNumberCacl(
            this.agreeForm.agreementAfterAmountTax || 0,
            this.agreeForm.deductibleTax || 0,
            "-",
            2
          );
        } else {
          this.agreeForm.agreementAfterAmountNoTax = utils.BigNumberCacl(
            this.responseContract.agreementAfterAmountNoTax || 0,
            0,
            "-",
            2
          );
        }
      },
      deep: true
    },
    pageType(newVal) {
      if (newVal == "edit") {
        this.hasStatus.show = true;
        this.showBtn = true;
      } else {
        this.hasStatus.show = false;
        this.showBtn = false;
      }
    },
    "agreeForm.currency": function(val) {
      if (this.agreeForm.needPay === "N") {
        if (val) {
          this.$set(this.formRules, "amount", [
            { required: true, message: "请根据所选类型输入对应的补充协议金额" }
          ]);
        } else {
          this.$set(this.formRules, "amount", [
            { required: false, message: "请根据所选类型输入对应的补充协议金额" }
          ]);
        }
      }
    },
    "agreeForm.amount": function(val) {
      if (this.agreeForm.needPay === "N") {
        if (val) {
          this.$set(this.formRules, "currency", [
            { required: true, message: "请选择币种" }
          ]);
        } else {
          this.$set(this.formRules, "currency", [
            { required: false, message: "请选择币种" }
          ]);
        }
      }
    }
  },
  created() {
    this.loading = this.$loading({
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    });
    this.currencyList = JSON.parse(localStorage.getItem("currencyList"));
    this.invoiceTypeList = JSON.parse(localStorage.getItem("invoiceTypeList"));
    this.getRightNowDate();

    this.getSubList().then(() => {
      this.getUrl().then(res => {
        this.getAgreeInfo(res);
      });
    });
    this.getCostDutePeo();
    this.getCostDep();
    this.getCostList();
  }
};
</script>
<style lang="less" scoped>
.option-wrapper {
  ::v-deep .el-select-dropdown__item.selected::after {
    top: 0;
    color: #997236;
  }
  .option-title {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name {
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag {
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #f7ebd4;
      border-radius: 2px;
      font-size: 10px;
      &.T02 {
        color: #7aa2f4 !important;
        background: #eef3fd;
      }
      &.T03 {
        color: #997236 !important;
        background: #f7ebd4;
      }
      &.T04 {
        color: #50ac94 !important;
        background: #e0f7f1;
      }
    }
  }
}

.page-box {
  .list-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px solid #eaeaea;
  }
  .detail-card {
    .card-header {
      .addSub-title {
        display: inline-block;
      }
      .sub-icon {
        display: inline-block;
        margin-left: 8px;
        margin-bottom: -2px;
        cursor: pointer;
      }
    }
    .card-content {
      .money-icon {
        // vertical-align: text-bottom;
        vertical-align: text-top;
        cursor: pointer;
      }
      .sign-col {
        display: flex;

        .select-sign {
          width: 72px !important;
        }

        .select-sign /deep/ .el-input__inner {
          border-right: 0 !important;
          border-radius: 4px 0 0 4px;
          background-color: #f5f5f5;
        }

        .select-sign /deep/ .el-select__caret {
          color: #323232;
          font-size: 12px;
        }

        .amount-vaild {
          flex: 1;
        }

        .amount-vaild /deep/ .el-form-item__content {
          margin-left: 0 !important;
        }

        .agree-amount {
          .amount-input /deep/ .el-input__inner {
            border-left: 0 !important;
            border-radius: 0 4px 4px 0;
          }
        }
      }

      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #efd193;
        background: #efd193;

        &::after {
          background-color: #323232;
          border-radius: 50%;
        }
      }
    }
    .body-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      div.body-cards {
        width: 49%;
        border: 2px solid #f0d8a8;
        border-radius: 6px;
        overflow: hidden;
        h2 {
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #323232;
          background: #fbf3e5;
          padding: 6px 18px;
          box-sizing: border-box;
        }
        ul {
          widows: 100%;
          padding: 0 18px;
          padding-top: 12px;
          box-sizing: border-box;
          li {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #323232;
            margin-bottom: 12px;
            span {
              display: inline-block;
              margin-left: 12px;
              font-weight: 400;
              font-size: 10px !important;
              line-height: 18px;
              color: #7aa2f4;
              background: #eef3fd;
              border-radius: 2px;
              // width: 32px;
              height: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

::v-deep {
  .el-form-item__error {
    position: relative;
    text-align: left;
  }
}
</style>
<style>
.el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
</style>
