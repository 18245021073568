<template>
  <div class="content page-box">
    <div class="page-title">
      <h4 class="title">合同变更</h4>
    </div>
    <section class="content-box">
      <el-form
        ref="changeForm"
        :model="changeForm"
        :rules="formRules"
        label-width="120px"
        size="small"
      >
        <!-- 合同变更信息 -->
        <div class="detail-card">
          <header class="card-header hasbtn">
            <TitleBar
              title="合同变更"
              :innerBtnBox="true"
              :hasStatus="hasStatus"
            >
              <div slot="btnBox">
                <button
                  class="btn btn-lucency"
                  @click="goMainCon"
                >查看主合同</button>
                <button
                  class="btn btn-lucency"
                  @click="goApproveDetail"
                >
                  审批详情
                </button>
              </div>
            </TitleBar>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item
                  label="合同变更类型:"
                  prop="typeStr"
                >
                  <el-input
                    type="text"
                    size="small"
                    v-model="changeForm.typeStr"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item label="合同类型:">
                  <el-input
                    type="text"
                    size="small"
                    v-model="changeForm.contractTypeValue"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="合同名称:"
                  prop="contractName"
                >
                  <el-input
                    type="text"
                    size="small"
                    v-model="changeForm.contractName"
                    @keydown.enter.prevent.native="handle"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="经办人:">
                  <el-input
                    type="text"
                    size="small"
                    v-model="changeForm.operatorName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="6"
                max-height="60px"
              >
                <el-form-item
                  label="经办日期:"
                  prop="contractDate"
                >
                  <el-date-picker
                    v-model="changeForm.contractDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                  <!-- <span
                    class="date-tip"
                    v-show="dateTip"
                  >请注意：所选日期早于当前日期</span> -->
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item label="形成方式:">
                  <el-select
                    v-model="changeForm.forType"
                    style="width: 100%"
                  >
                    <template v-for="(item, index) of forTypeList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.code"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否需要付款:">
                  <el-radio-group
                    disabled
                    v-model="changeForm.needPay"
                  >
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <!-- 是否供方合同 -->
              <el-col :span="6">
                <el-form-item
                  label="是否供方合同:"
                  prop="supplyStoreFlag"
                >
                  <el-radio-group v-model="changeForm.supplyStoreFlag" @change="changeContractSource">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item
                  label="关联项目:"
                  prop="projectGoalFlag"
                >
                  <el-select
                    v-model="changeForm.projectGoalFlag"
                    style="width: 100%"
                    disabled
                  >
                    <el-option
                      label="非项目目的"
                      value="N"
                    />
                    <el-option
                      label="项目目的"
                      value="Y"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item class="correlate">
                  <el-input
                    disabled
                    placeholder="-"
                    v-if="changeForm.projectGoalFlag === 'N'"
                  />
                  <el-input
                    v-else
                    disabled
                    v-model="changeForm.projectName"
                  />
                </el-form-item>
              </el-col>
                      <el-col :span="6" v-if="changeForm.kgStage">
                <el-form-item
                  label="所属阶段:"
                  prop="kgStage"
                >
                  <el-select v-model="changeForm.kgStage" disabled>
                             <el-option label="投资阶段" value="1"/>
                    <el-option label="退出阶段" value="2"/>
                  </el-select>
                </el-form-item>
              </el-col>
                      <el-col :span="6" v-if="changeForm.isRelationFixPj == 1">
                <el-form-item
                  label="具体合同类型:"
                  prop="fixContractType"
                >
                  <el-select v-model="changeForm.fixContractType" disabled>
                    <el-option label="CapEX合同" value="1"/>
                    <el-option label="OpEX合同" value="2"/>
                    <el-option label="通用合同" value="0"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>

        <!-- 签约主体 -->
        <div class="detail-card">
          <header class="card-header">
            <h4 class="second-title">签约主体</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item
                  label="我方签约主体:"
                  prop="ourCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    :remote-method="searchOurCompany"
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-model="changeForm.ourCompany"
                    style="width: 100%"
                    ref="ourCompany"
                    @focus="clearOptions"
                  >
                    <template v-for="(item, index) of ourCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="对方签约主体:"
                  prop="supplierCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    :remote-method="searchSupplierCompany"
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-model="changeForm.supplierCompany"
                    style="width: 100%"
                    ref="supplierCompany"
                    @focus="clearOptions"
                  >
                    <div slot="" class="option-wrapper">
                        <template>
                          <el-option
                            v-for="(item, index) of supplierCompanyListNew"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          >
                            <p class="option-title">
                              <span class="option-name" v-maia-ellipsis>{{ item.name }}</span>
                              <span :class="['tag', item.type]">{{ item.type === 'T02' ? '被投方' : (item.type === 'T03' ? '合作方' : '供方') }}</span>
                            </p>
                          </el-option>
                        </template>
                      </div>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>

        <!-- 招采信息 -->
        <div class="detail-card">
          <header class="card-header">
            <h4 class="second-title">招采信息</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="18">
                <el-form-item
                  label="招标/比价/直委:"
                  prop="orderMsg"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    placeholder="请输入招标/比价/直委说明信息"
                    v-model="changeForm.orderMsg"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <UploadComponent
              v-model="forminingfileList"
              :fileUploadList="forminingfileList"
              :fileCategory="'RECRUIT'"
              :updateFileList=this.updateFileList
              :label="''"
              :btnName="'上传招采附件'"
              :limit="10"
              :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
            />

          </section>
        </div>

        <!-- 合同金额 -->
        <div class="detail-card">
          <header class="card-header">
            <h4 class="second-title">合同金额</h4>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item
                  label="币种:"
                  prop="currency"
                >
                  <el-select
                    v-model="changeForm.currency"
                    style="width: 100%"
                    :disabled="currencyDisabled"
                    :clearable="true"
                  >
                    <template v-for="(item, index) of currencyList">
                      <el-option
                        :key="index"
                        :label="item.value"
                        :value="item.code"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="合同金额(含税)(A):"
                  prop="amount"
                >
                  <el-input
                    class="amount-input"
                    v-model="changeForm.amount"
                    @input="limitInput($event, 'amount')"
                    @keydown.enter.prevent.native="handle"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="不计成本金额(B):"
                  prop="costAmount"
                >
                  <el-input
                    class="amount-input"
                    placeholder="请输入金额"
                    v-model="changeForm.costAmount"
                    @input="limitInput($event, 'costAmount')"
                    @keydown.enter.prevent.native="handle"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  prop="effContractAmount"
                  class="label-style"
                >
                  <span slot="label">有效签约金额<br />(C=A-B):</span>
                  <el-input
                    class="amount-input"
                    placeholder="-"
                    v-model="changeForm.effContractAmount"
                    disabled
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item
                  label="发票类型:"
                  prop="invoiceType"
                >
                  <el-select
                    v-model="changeForm.invoiceType"
                    style="width: 100%"
                    :popper-append-to-body="false"
                  >
                    <template v-for="(item, index) of invoiceTypeList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.code"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="可抵扣税率:"
                  prop="deductibleTaxRate"
                >
                  <el-input
                    class="amount-input"
                    placeholder="请输入可抵扣税率"
                    v-model="changeForm.deductibleTaxRate"
                    @input="limitInput($event, 'deductibleTaxRate')"
                    @keydown.enter.prevent.native="handle"
                  >
                    <i
                      slot="suffix"
                      :style="{'color':'#999','paddingRight': 6+'px'}"
                    > % </i>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="可抵扣税额:"
                  prop="deductibleTax"
                >
                  <el-input
                    disabled
                    class="amount-input"
                    v-model="changeForm.deductibleTax"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  prop="exContractAmount"
                  class="label-style"
                >
                  <span slot="label">合同金额<br />(不含抵扣税):</span>
                  <el-input
                    v-model="changeForm.exContractAmount"
                    class="amount-input"
                    placeholder="-"
                    disabled
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item
                  prop="aboveQuotaType"
                  class="label-style"
                >
                  <span slot="label">可超额付款<br />比例/金额:</span>
                  <el-select
                    v-model="changeForm.aboveQuotaType"
                    style="width: 100%"
                    :popper-append-to-body="false"
                  >
                    <el-option
                      label="按比例"
                      value="RATE"
                    />
                    <el-option
                      label="按金额"
                      value="FIX"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="overPayValue">
                  <div
                    class="toggle"
                    v-if="changeForm.aboveQuotaType == 'FIX'"
                  >
                    <el-input
                      class="amount-input"
                      v-model="changeForm.aboveQuotaAmount"
                      @input="limitInput($event, 'aboveQuotaAmount')"
                      @keydown.enter.prevent.native="handle"
                    >
                    </el-input>
                  </div>
                  <div
                    class="toggle"
                    v-else
                  >
                    <el-input
                      class="amount-input"
                      v-model="changeForm.aboveQuotaScale"
                      @input="limitInput($event, 'aboveQuotaScale')"
                      @keydown.enter.prevent.native="handle"
                    >
                      <i
                        slot="suffix"
                        :style="{'color':'#323232','paddingRight': 6+'px'}"
                      > % </i>
                    </el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-row> -->
          </section>
        </div>

        <!-- 费项分摊 -->
        <div class="detail-card" v-if="changeForm.needPay === 'Y'">
          <header class="card-header cost">
            <h4 class="second-title">费项分摊</h4>
            <button
              class="btn btn-primary"
              @click.prevent="addCostList"
            >
              <i class="el-icon-circle-plus-outline iconStyle"></i>
              新增
            </button>
          </header>
          <section class="card-content">
            <el-table
              :data="changeForm.costExpenseList"
              ref="costExpenseList"
              style="width: 100%"
              border
              fit
            >
              <div
                slot="empty"
                class="empty-box"
              >
                <button
                  class="btn"
                  @click.prevent="addCostList"
                >
                  <i class="el-icon-circle-plus-outline"></i>
                </button>
                <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
              </div>
              <el-table-column
                label="责任人"
                prop="operatorNo"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="cost-card"
                    :prop="'costExpenseList.'+ scope.$index + '.operatorNo'"
                    :rules="costFormRules.operatorNo"
                  >
                    <el-select
                      filterable
                      v-model="scope.row.operatorNo"
                      size="small"
                    >
                      <el-option
                        v-for="(item, index) of dutyPeoList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="费项"
                prop="costItemsId"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="cost-card"
                    :prop="'costExpenseList.'+ scope.$index + '.costItemsId'"
                    :rules="costFormRules.costItemsId"
                  >
                    <el-select
                      filterable
                      v-model="scope.row.costItemsId"
                      size="small"
                    >
                      <el-option
                        v-for="(item, index) of costDetailList"
                        :key="index"
                        :label="item.F_CAPTION"
                        :value="item.FK_KMBM"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="预算分摊部门"
                prop="costItemsDept"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="cost-card"
                    :prop="'costExpenseList.'+ scope.$index + '.costItemsDept'"
                    :rules="costFormRules.costItemsDept"
                  >
                    <el-select
                      filterable
                      v-model="scope.row.costItemsDept"
                      size="small"
                    >
                      <el-option
                        v-for="(item, index) of costShareDepartList"
                        :key="index"
                        :label="item.F_CAPTION"
                        :value="item.F_ID"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="分摊金额(含税)"
                prop="amount"
                align="right"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="cost-card"
                    :prop="'costExpenseList.'+ scope.$index + '.amount'"
                    :rules="costFormRules.amount"
                  >
                    <el-input
                      size="small"
                      v-model="scope.row.amount"
                      class="amount-input"
                      @input="()=>{ scope.row.amount = limitInput(scope.row.amount) }"
                      @keydown.enter.prevent.native="handle"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="60px"
                align="left"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      class="operate-btn"
                      type="text"
                      @click="deleteCost(scope.$index, scope.row)"
                    > 删除
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </section>
        </div>

        <!-- 合同付款约定 -->
        <div class="detail-card" v-if="changeForm.needPay === 'Y'">
          <header class="card-header describe">
            <h4 class="second-title">合同付款约定</h4>
            <span>请说明首付款、过程付款、尾款的付款条件、时间及金额</span>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item
                  label="说明信息:"
                  prop="payAgreement"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="changeForm.payAgreement"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>

        <!-- 说明 -->
        <div class="detail-card">
          <header class="card-header describe">
            <h4 class="second-title">合同说明</h4>
            <span>请明确本次合同签订的范围及目的，以供审批人直观审批</span>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item
                  label="说明信息:"
                  prop="contractNote"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="changeForm.contractNote"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 信息修改原因 -->
        <div class="detail-card">
          <header class="card-header describe">
            <h4 class="second-title">信息修改原因</h4>
            <span>针对本次信息修改的内容进行说明，以供审批人直观审批</span>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item
                  label="说明信息:"
                  prop="contractNote"
                >
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="changeForm.infoChangeCause"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>

        <!-- 文档 -->
        <div class="detail-card no-bottom-border">
          <header class="card-header">
            <h4 class="second-title">文档</h4>
          </header>
          <section class="card-content">

            <UploadComponent
              v-model="changeForm.contractFiles"
              :label="label"
              :fileCategory="'CONTRACT'"
              :updateFileList=this.updateFileList
              :showRemark="true"
              :btnName="'上传文件'"
              :limit="10"
              :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
            />

          </section>
        </div>
      </el-form>
    </section>
    <footer class="footer">
      <div class="btnBox">
        <!-- <button
          class="btn btn-lucency"
          @click="deleteChange"
        >删除</button> -->
        <button
          class="btn btn-lucency"
          @click="goMainCon"
        >返回</button>
        <button
          class="btn btn-primary btn-temporary"
          @click="storage"
          :disabled="storageDisabled"
          :class="storageDisabled?'storage_disabled':''"
        >
          暂存
        </button>
        <button
          class="btn btn-primary"
          @click="approval"
        >下一步</button>
      </div>
    </footer>
  </div>
</template>
<script>
import TitleBar from "@/components/common/TitleBar.vue";
import UploadComponent from "@/components/common/UploadComponent.vue"
import utils from '@/utils/index'

export default {
  name: "changeEdit",
  components: {
    TitleBar,
    UploadComponent
  },
  data() {
    let contractFiles = (rule, value, callback) => {
      let index = this.changeForm.contractFiles.findIndex(v => v.status === "success")
      if (index == '-1') {
        callback(new Error('附件不能为空'))
      } else  {
        callback()
      }
    }
    return {
      label: "附件：",
      id: "", // 信息修改ID
      contractId: "", // 主合同ID
      approveUrl: "", // 审批地址
      currencyDisabled: false, // 币种是否禁用
      // 审批状态
      hasStatus: {
        show: true,
        status: "",
      },
      // 修改表单
      changeForm: {
        costExpenseList: [], // 费项
        ourCompany: [],
        supplierCompany: [],
        contractFiles: [],
        supplyStoreFlag: '1'
      },
      // 表单验证
      formRules: {
        contractName: [{ required: true, message: "请填写合同名称" }],
        contractDate: [{ required: true, message: "请选择经办日期" }],
        ourCompany: [{ required: true, message: "请选择我方主体" }],
        supplierCompany: [{ required: true, message: "请选择对方主体" }],
        currency: [{ required: true, message: "请选择币种" }],
        invoiceType: [{ required: true, message: "请选择发票类型" }],
        amount: [{ required: true, message: "请填写合同金额" }],
        contractFiles: [{ required: true, validator: contractFiles }],
        supplyStoreFlag: [{ required: true, message: "请选择是否供方合同" }]
      },
      // 费项表单验证
      costFormRules:{
        operatorNo: [{ required: true, message: "请选择责任人" }],
        costItemsId: [{ required: true, message: "请选择费项" }],
        costItemsDept: [{ required: true, message: "请选择费项分摊部门" }],
        amount: [{ required: true, message: "请输入金额"}]
      },

      //发票类型列表
      invoiceTypeList: [],
      // 形成方式
      forTypeList: [],
      // 币种列表
      currencyList: [],
      // 主体列表
      ourCompanyList: [],
      supplierCompanyList: [],
      supplierCompanyListNew: [],

      subList: [],

      // 费项责任人列表
      dutyPeoList: [],
      // 费项列表
      costDetailList: [],
      // 费项预算分摊部门列表
      costShareDepartList: [],

      forminingfileList: [],

      totalFileList: [],


      loading: null, // 全局loading
      //dateTip: false, // 日期选择提示
      storageDisabled: false, // 暂存按钮禁用

      // 校验供方合同是否选择供方主体
      checkContractNext: true,
      supplierIdArray: [],
    };
  },
  methods: {
    handle() {},
    // 是否供方合同
    // 获取所有供方主体id存为数组
    getSupplierIdArray() {
      this.supplierCompanyList.forEach(item => {
        if (item.type === 'T04') {
          this.supplierIdArray.push(item.id)
        }
      })
    },
    // 校验是否供方合同并做处理
    checkContractSource() {
      this.checkContractNext = true
      if (this.changeForm.supplyStoreFlag === '1' && this.changeForm.supplierCompany) {
        this.changeForm.supplierCompany.forEach(item => {
          if(this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false
          }
        })
      }
      if (this.changeForm.supplyStoreFlag === '0') {
        this.checkContractNext = false
      }
    },
    // 改变合同来源
    changeContractSource(val) {
      if (val === '0') {
        // this.supplierCompanyListNew = []
        this.supplierCompanyList.forEach(item => {
          if (item.type === 'T02' || item.type === 'T03') {
            this.supplierCompanyListNew.push(item)
          }
        })
        if (this.changeForm.supplierCompany) {
          this.changeForm.supplierCompany.forEach((items, index) => {
            if (this.supplierIdArray && this.supplierIdArray.includes(items)) {
              this.changeForm.supplierCompany.splice(index, 1)
            }
          })
        }
      }
      //  else {
      //   this.supplierCompanyListNew = this.supplierCompanyList
      // }
    },
    // 信息修改部分币种和金额是否必填
    changeMoneyCheck(val) {
      if (val !== 'Y') {
        this.$set(this.formRules, 'invoiceType', [{ required: false, message: "请选择发票类型" }])
      } else {
        this.$set(this.formRules, 'invoiceType', [{ required: true, message: "请选择发票类型" }])
      }
      if (val !== 'Y' && !this.changeForm.amount && !this.changeForm.currency) {
        this.$set(this.formRules, 'amount', [{ required:false, message: "请填写合同变更金额" }])
        this.$set(this.formRules, 'currency', [{ required:false, message: "请选择币种" }])
      } else {
        this.$set(this.formRules, 'amount', [{ required:true, message: "请填写合同变更金额" }])
        this.$set(this.formRules, 'currency', [{ required:true, message: "请选择币种" }])
      }
    },

    updateFileList(file, fileCategory) {
      if (fileCategory == "CONTRACT") {
        let index = this.changeForm.contractFiles.findIndex(f => f.fileId === file.fileId)
        if(index !== -1) {
            return;
        }
        this.changeForm.contractFiles = this.changeForm.contractFiles.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.changeForm.contractFiles = [...this.changeForm.contractFiles, file]
      } else {
        let index = this.forminingfileList.findIndex(f => f.fileId == file.fileId)
        if(index !== -1) {
            return;
        }
        this.forminingfileList = this.forminingfileList.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.forminingfileList = [...this.forminingfileList, file]
      }
    },

    //从子组件获取附件列表
    uploadFile(params){
      params.forEach(item => {
        item.fileCategory = "CONTRACT"
      });
      this.changeForm.contractFiles = params
    },

    //招采类附件列表
    uploadForminingFile(params) {
      params.forEach(item => {
        item.fileCategory = "RECRUIT"
      });
      this.forminingfileList = params
    },
    // 获取路径来源
    getUrl() {
      return new Promise((resolve) => {
        let id = this.$route.params.id;
        this.id = id;
        resolve(id);
      });
    },
    // 获取变更信息
    getChangeInfo(id) {
      let params = { id }
      this.$request
        .post("/contractModify/getModifyInfo", { params })
        .then((res) => {
          if(res.code === "200") {
            let data = res.data
            //把回传的文件状态赋值为成功
            data.attachFileList.forEach((item, index) => {
              item.status = 'success'
              item.uid = 'a'+ index
            })

            this.changeForm = data
            this.changeForm.fixContractType = String(data.fixContractType)
            this.changeForm.changeForm = data.changeForm
            // 不需要付款处理
            this.changeMoneyCheck(this.changeForm.needPay)
            let fileList = data.attachFileList
            this.forminingfileList = fileList.filter((item) => {
              return item.fileCategory == "RECRUIT"
            })

            this.changeForm.amount = Number(data.amount).toFixed(2)

            this.changeForm.contractFiles = fileList.filter((item) => {
              return item.fileCategory == "CONTRACT";
            })

            if(data.payStatus === 'Y') {
              this.currencyDisabled = true
            } else {
              this.currencyDisabled = false
            }

            this.changeForm.typeStr = "信息修改"
            this.changeForm.projectName = utils.formatSubName(data.projectName)
            this.changeForm.contractDate = data.contractDateStr

            this.hasStatus.status = data.auditStatus
            this.contractId = data.contractId
            this.approveUrl = data.viewUrl

            data.deductibleTaxRate? this.changeForm.deductibleTaxRate = (utils.BigNumberCacl(data.deductibleTaxRate, '100', '*', 'noFixed')) : this.changeForm.deductibleTaxRate = null
            // data.aboveQuotaScale ? this.changeForm.aboveQuotaScale = (data.aboveQuotaScale * 100).toFixed(2) : this.changeForm.aboveQuotaScale = null
            data.aboveQuotaScale ? this.changeForm.aboveQuotaScale = (utils.BigNumberCacl(data.aboveQuotaScale, '100', '*', 'noFixed')) : this.changeForm.aboveQuotaScale = null

            this.changeForm.ourCompany = utils.formatSubId(data.ourCompanyId)
            this.changeForm.supplierCompany = utils.formatSubId(data.supplierCompanyId)

            // 初始化主体列表
            this.ourCompanyList = (this.subList.T01).filter(element=>{
              return this.changeForm.ourCompany.indexOf(element.id)!==-1
            })
            this.supplierCompanyListNew = (this.subList.T02).filter(element=>{
              return this.changeForm.supplierCompany.indexOf(element.id)!==-1
            })

            // 是否供方合同
            this.$set(this.changeForm, 'supplyStoreFlag', data.supplyStoreFlag || '1')
            this.changeContractSource(this.changeForm.supplyStoreFlag)

            // this.supplierCompanyList = (this.subList.T02).filter(element=>{
            //   return this.changeForm.supplierCompany.indexOf(element.id)!==-1
            // })
          }
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
        }).catch((error) =>{
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.$message.error(error.message)
        });
    },

    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value,name,this.changeForm)
    },

    // 信息修改-经办日期修改提示
    // dateChange(val) {
    //   if (val < this.rightNow) {
    //     this.dateTip = true;
    //   } else {
    //     this.dateTip = false;
    //   }
    // },

    // 获取费项列表责任人
    getCostDutePeo() {
      this.$request.post("/employee/queryEmployeeAll?scope=").then((res) => {
        if(res.code === "200") {
          this.dutyPeoList = res.data
        }
      })
    },
    // 获取费项预算部门
    getCostDep() {
      this.$request.post("/cost/queryCostItemDept").then((res) => {
        if(res.code === "200") {
          this.costShareDepartList = res.data
        }
      })
    },
    // 获取费项选择列表
    getCostList() {
      this.$request.post("/cost/queryCostItem").then((res) => {
        if(res.code === "200") {
          this.costDetailList = res.data
        }
      })
    },

    // 删除当前费项行
    deleteCost(index, row) {
      this.changeForm.costExpenseList.splice(index, 1)
    },
    // 增加费项行
    addCostList() {
      this.changeForm.costExpenseList.push({});
    },

    // 下一步保存合同
    saveChange(type) {
      //合并招采附件，合同附件，去除保存不成功附件
      this.totalFileList = this.changeForm.contractFiles.concat(
        this.forminingfileList
      )

      //将上传完成的文件放入changeForm
      var temp = []
      this.totalFileList.forEach(item => {
        let index = temp.findIndex(v => v.uid === item.uid)
        if ("success" === item.status && "-1" == index) {
          if(item.partFiles) {
            item.partFiles = ''
          }
          temp = [
            {...item},
            ...temp
          ]
        }
      })
      this.changeForm.attachFileList = temp

      // 处理费项数据结构
      if(this.changeForm.costExpenseList.length !== 0) {
        let costList = this.changeForm.costExpenseList
        costList.forEach(item => {
          this.costDetailList.forEach(it =>{
            if(it.FK_KMBM === item.costItemsId) {
              item.costItemsName = it.F_CAPTION
            }
          })
          this.costShareDepartList.forEach(it => {
            if(it.F_ID === item.costItemsDept) {
              item.costItemsDeptName = it.F_CAPTION
            }
          })
        })
      }

      let params = {...this.changeForm}
      params.ourCompany = this.$formatSubList("ourCompany", this.changeForm)
      params.supplierCompany = this.$formatSubList("supplierCompany", this.changeForm)


      if(this.changeForm.projectId) {
        params.relevanceProjectInfo = utils.formatSubName(this.changeForm.projectId)
      }

      if(type == "draft") {
        params.auditStatus = this.changeForm.auditStatus
      } else {
        params.auditStatus = ''
      }

      return new Promise(resolve =>{
        this.$request.post("/contractAgreement/saveAgreement", { params }).then(res => {
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.storageDisabled = false
          if(res.code === "200" && res.data) {
            resolve(res.data)
          }
        }).catch((error) =>{
          // 关闭全局loading
          if(this.loading) {
            this.loading.close()
          }
          this.$message.error(error)
        })
      })
    },

    // 下一步(提交)
    approval() {
      this.checkContractSource()
      if (this.checkContractNext) {
        this.$message.error('供方合同请至少选择一个供方主体')
        return
      }
      this.$refs["changeForm"].validate((valid) => {
        if (valid) {
          // 打开全局loading
          this.loading = this.$loading({
            fullscreen: true,
            background: "rgba(255, 255, 255, 0.8)",
            text: "正在保存数据..."
          })

          this.saveChange('change').then(res => {
            this.$message({
              type: "success",
              message: "保存成功，即将跳转审批页面",
              duration: 1500,
              onClose: () => {
                var pWin = window.open('_blank')
                pWin.location = res
                window.opener = null;
                window.open("about:blank", "_top").close()
              }
            })
          }).catch((error) =>{
            // 关闭全局loading
            if(this.loading) {
              this.loading.close()
            }
            this.$message.error(error)
          })

        }
      })
    },

    // 暂存
    storage(){
      this.storageDisabled = true
      this.saveChange('draft').then(res => {
        this.$message({
          type: "success",
          message: "暂存成功",
          duration: 1000,
        })
      })
    },

    // 删除变更合同
    deleteChange() {
      this.$confirm("是否立即删除此合同，删除后，将无法恢复", "删除文件", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
        lockScroll: false
      })
        .then(() => {
          let params = { id: this.id };
          this.$request
            .post("/contractModify/deleteModify", { params })
            .then((res) => {
              if (res.code === "200" && res.data == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1000,
                  onClose: () => {
                    // 关闭当前页
                    window.opener = null;
                    window.open("about:blank", "_top").close()
                  }
                })
              }
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        });
    },
    // 查看主合同
    goMainCon() {
      this.$open({
        name: "conDetail",
        path: `/condetail/${this.contractId}`,
        params: {
          id: this.contractId,
        },
      });
    },
    // 查看审批详情
    goApproveDetail() {
      if(this.approveUrl) {
        window.open(this.approveUrl)
      } else {
        window.open(`/nobpm.html`)
      }
    },

    // 通用-获取主体列表
    getSubList() {
      return new Promise(resolve => {
        this.$request.post("/enterprise/queryEnterpriseAll").then((res) => {
          if(res.code === "200") {
            this.subList = res.data
            this.supplierCompanyList = res.data.T02
            this.getSupplierIdArray()
            resolve()
          }
        })
      })
    },

     // 远程搜索我方主体-主体变更/信息修改
    searchOurCompany(keyWords){
      this.ourCompanyList = this.$optionDataFilter(keyWords,this.subList.T01)
    },

    // 远程搜索对方主体-主体变更/信息修改
    searchSupplierCompany(keyWords){
      this.supplierCompanyListNew = this.$optionDataFilter(keyWords,this.subList.T02)
    },

    clearOptions() {
      this.ourCompanyList = []
      this.supplierCompanyListNew = []
    }


  },
  computed: {
    listenChange () {
      const {amount, costAmount, deductibleTaxRate} = this.changeForm
      return {amount, costAmount, deductibleTaxRate}
    },
  },
  watch: {
    listenChange: {
      handler(val, oldval) {
        // 合同变更金额,合同变更金额（不含税）,可抵扣税率
        if (val.amount) {
          if (val.deductibleTaxRate) {
            let middle = utils.BigNumberCacl(this.changeForm.amount, (1 + (val.deductibleTaxRate) / 100), '/')
            let tax = utils.BigNumberCacl((val.deductibleTaxRate / 100), middle, '*')
            this.changeForm.deductibleTax = tax

            this.changeForm.exContractAmount =
              utils.BigNumberCacl(this.changeForm.amount, this.changeForm.deductibleTax, '-')
          } else {
            this.changeForm.exContractAmount = utils.BigNumberCacl(this.changeForm.amount, '0', '-')
            this.changeForm.deductibleTax = null
          }

          if(val.costAmount) {
            this.changeForm.effContractAmount =
              utils.BigNumberCacl(this.changeForm.amount, this.changeForm.costAmount, '-')
          }else {
            this.changeForm.effContractAmount = utils.BigNumberCacl(this.changeForm.amount, '0', '-')
          }
        } else {
          this.changeForm.effContractAmount = null
          this.changeForm.exContractAmount = null
          this.changeForm.deductibleTax = null
        }
      },
      deep: true,
    },
    'changeForm.currency': function(val) {
      if (this.changeForm.needPay === 'N') {
        if (val) {
          this.$set(this.formRules, 'amount', [{ required: true, message: "请填写合同变更金额" }])
        } else {
          this.$set(this.formRules, 'amount', [{ required: false, message: "请填写合同变更金额" }])
        }
      }
    },
    'changeForm.amount': function(val) {
      if (this.changeForm.needPay === 'N') {
        if (val) {
          this.$set(this.formRules, 'currency', [{ required: true, message: "请选择币种" }])
        } else {
          this.$set(this.formRules, 'currency', [{ required: false, message: "请选择币种" }])
        }
      }
    },
  },
  created() {
    // 打开全局loading
    this.loading = this.$loading({
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    })

    this.currencyList = JSON.parse(localStorage.getItem("currencyList"))
    this.invoiceTypeList = JSON.parse(localStorage.getItem("invoiceTypeList"))
    this.forTypeList = JSON.parse(localStorage.getItem("forTypeList"))

    this.getSubList().then(() => {
      this.getUrl().then((res) => {
        this.getChangeInfo(res);
      })
    })


    this.getCostDutePeo()
    this.getCostDep()
    this.getCostList()
  },
};
</script>
<style lang="less" scoped>
.option-wrapper{
  ::v-deep .el-select-dropdown__item.selected::after{
    top: 0;
    color: #997236;
  }
  .option-title{
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name{
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag{
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #F7EBD4;
      border-radius: 2px;
      font-size: 10px;
      &.T02{
        color: #7AA2F4 !important;
        background: #EEF3FD;
      }
      &.T03{
        color: #997236 !important;
        background: #F7EBD4;
      }
      &.T04{
        color: #50AC94 !important;
        background: #E0F7F1;
      }
    }
  }
}
.page-box {
  .page-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #eaeaea;
    padding: 6px 12px;
    line-height: 36px;
  }
  .detail-card {
    .card-header {
      .statusBox {
        box-sizing: border-box;
        max-width: 70px;
        height: 20px;
        line-height: 14px;
        // margin: 8px 0 0 10px;
        padding: 4px 6px;
        font-size: 12px;
        position: absolute;
        top: 8px;
        left: 105px;

        .statusIcon {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          margin-right: 2px;
          margin-bottom: 1px;
        }
      }
    }
    .hasbtn {
      display: flex;
      justify-content: space-between;
    }
    .card-content {
      .correlate {
        margin-left: -120px;
      }
      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #efd193;
        background: #efd193;

        &::after {
          background-color: #323232;
          border-radius: 50%;
        }
      }
    }

    .describe {
      h4,
      span {
        display: inline-block;
      }
      span {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
  }
  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
::v-deep .el-radio__input.is-checked+.el-radio__label{
  color: #606266;
}
</style>
