import { render, staticRenderFns } from "./changeEdit.vue?vue&type=template&id=13348aea&scoped=true"
import script from "./changeEdit.vue?vue&type=script&lang=js"
export * from "./changeEdit.vue?vue&type=script&lang=js"
import style0 from "./changeEdit.vue?vue&type=style&index=0&id=13348aea&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13348aea",
  null
  
)

export default component.exports