<template>
  <div class="content page-box">
    <div class="list-title">
      <h4 class="title">新建合同变更</h4>
    </div>
    <section class="content-box">
      <el-form
        ref="formData"
        :model="formData"
        :rules="formRules"
        label-width="120px"
        size="small"
        @submit.native.prevent
      >
        <!-- 合同变更信息 -->
        <div
          class="detail-card detail-card-nopadding"
          :class="{ 'no-bottom-border': formData.type === 'MONEY' }"
        >
          <header class="card-header">
            <!-- <h4 class="second-title">合同变更信息</h4> -->
            <TitleBar title="合同变更信息" :innerBtnBox="true">
              <div slot="btnBox">
                <button class="btn btn-lucency" @click="goMainCon">
                  查看主合同
                </button>
              </div>
            </TitleBar>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="6">
                <el-form-item prop="type">
                  <span slot="label"
                    >变更类型<i
                      class="el-icon-warning-outline tip-icon"
                      @mouseenter="
                        () => {
                          this.showTips = true;
                        }
                      "
                      @mouseleave="
                        () => {
                          this.showTips = false;
                        }
                      "
                    ></i
                    >:</span
                  >
                  <div class="triangle" v-if="showTips"></div>
                  <ul class="tips-list" v-if="showTips">
                    <li>补充协议：指在原合同基础之上增加一份新协议</li>
                    <li>
                      信息修改：若原合同字段信息填写错误，可对错误字段内容进行修改
                    </li>
                    <li>
                      主体变更：指修改原合同的签约主体，需签约双方重新盖章
                    </li>
                    <li>解除协议：将原合同作废，不再具有法律效益</li>
                    <li v-if="formData.needPay === 'Y'">
                      框架类协议金额修改：对框架类合同的金额进行修改，只能修改合同金额
                    </li>
                  </ul>
                  <el-select
                    v-model="formData.type"
                    style="width: 100%"
                    @change="typeChange"
                  >
                    <template v-for="(item, index) of changeType">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              :gutter="10"
              v-if="
                formData.type === 'SIDE_AGREEMENT' ||
                  formData.type === 'RELEASE_AGREEMENT'
              "
            >
              <el-col :span="6">
                <el-form-item label="主合同名称:" prop="contractName">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="主合同编号:" prop="contractNo">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractNo"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="主合同类型:" prop="contractTypeValue">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractTypeValue"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="operatorName">
                  <span slot="label" v-if="formData.type === 'SIDE_AGREEMENT'"
                    >补充协议经办人:</span
                  >
                  <span
                    slot="label"
                    v-if="formData.type === 'RELEASE_AGREEMENT'"
                  >
                    解除协议经办人:</span
                  >
                  <el-input
                    type="text"
                    size="small"
                    v-model="currentUserName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" v-if="formData.type === 'SUBJECT_CHANGE'">
              <el-col :span="8">
                <el-form-item label="主合同名称:" prop="contractName">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="主合同编号:" prop="contractNo">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractNo"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="主合同类型:" prop="contractTypeValue">
                  <el-input
                    type="text"
                    size="small"
                    v-model="conInfo.contractTypeValue"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 补充协议 -->
            <el-row :gutter="10" v-if="formData.type === 'SIDE_AGREEMENT'">
              <el-col :span="6">
                <el-form-item
                  label="补充协议名称:"
                  prop="form_agree.agreementName"
                >
                  <el-input
                    type="text"
                    size="small"
                    maxlength="255"
                    v-model="formData.form_agree.agreementName"
                    @keydown.enter.native.prevent="handle"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="补充协议经办日期:"
                  class="label-style"
                  prop="form_agree.modifyDate"
                >
                  <span slot="label">补充协议经办<br />日期:</span>
                  <el-date-picker
                    v-model="formData.form_agree.modifyDate"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    @change="agreeDateChange"
                  >
                  </el-date-picker>
                  <span class="date-tip" v-show="agree_dateTip"
                    >请注意：所选日期早于当前日期</span
                  >
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="needPay" class="label-style">
                  <span slot="label">补充协议是否<br />需要付款:</span>
                  <el-radio-group
                    v-if="needPayBtnOption === 'N'"
                    v-model="formData.form_agree.needPay"
                    disabled
                  >
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                  <el-radio-group
                    @change="radioChangeEvents"
                    v-model="formData.form_agree.needPay"
                    v-if="needPayBtnOption === 'Y'"
                  >
                    <el-radio label="Y">是</el-radio>
                    <el-radio label="N">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <!-- 是否供方合同 -->
              <el-col :span="6">
                <el-form-item label="是否供方合同:" prop="supplyStoreFlag">
                  <el-radio-group
                    disabled
                    v-model="formData.supplyStoreFlag"
                    @change="changeContractSourceAdd"
                  >
                    <el-radio label="1">是</el-radio>
                    <el-radio label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 主体变更 -->
            <el-row v-if="formData.type === 'SUBJECT_CHANGE'">
              <el-col :span="8">
                <el-form-item prop="operatorName">
                  <span slot="label" v-if="formData.type === 'SUBJECT_CHANGE'"
                    >主体变更经办人:</span
                  >
                  <el-input
                    type="text"
                    size="small"
                    v-model="currentUserName"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <!-- 是否供方合同 -->
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="是否供方合同:" prop="supplyStoreFlag">
                    <el-radio-group
                      disabled
                      v-model="formData.supplyStoreFlag"
                      @change="changeContractSource"
                    >
                      <el-radio label="1">是</el-radio>
                      <el-radio label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="原我方签约主体:">
                    <el-input
                      type="text"
                      size="small"
                      v-model="conInfo.ourCompany"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="现我方签约主体:"
                    prop="form_change.ourCompany_change"
                  >
                    <el-select
                      multiple
                      filterable
                      placeholder="请输入不少于4个字的主体名称进行查询"
                      remote
                      :remote-method="searchOurCompany"
                      v-model="formData.form_change.ourCompany_change"
                      style="width: 100%"
                      ref="ourCompany_change"
                      @focus="clearOptions"
                    >
                      <template v-for="(item, index) of ourCompanyList">
                        <el-option
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="原对方签约主体:">
                    <el-input
                      type="text"
                      size="small"

                      v-model="conInfo.supplierCompany"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="现对方签约主体:"
                    prop="form_change.supplierCompany_change"
                  >
                    <el-select
                      multiple
                      filterable
                              placeholder="请输入不少于4个字的主体名称进行查询"
                      remote
                      :remote-method="searchSupplierCompany"
                      v-model="formData.form_change.supplierCompany_change"
                      style="width: 100%"
                      ref="supplierCompany_change"
                    >
                      <div slot="" class="option-wrapper">
                        <template>
                          <el-option
                            v-for="(item) of supplierCompanyListNew"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                            <p class="option-title">
                              <span class="option-name" v-maia-ellipsis>{{
                                item.name
                              }}</span>
                              <span :class="['tag', item.type]">{{
                                item.type === "T02"
                                  ? "被投方"
                                  : item.type === "T03"
                                  ? "合作方"
                                  : "供方"
                              }}</span>
                            </p>
                          </el-option>
                        </template>
                      </div>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <!-- 信息修改 -->
            <el-row v-if="formData.type === 'MODIFY'">
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item label="合同类型:">
                    <el-input
                      type="text"
                      size="small"
                      v-model="formData.form_modify.contractTypeValue"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="合同名称:"
                    prop="form_modify.contractName"
                  >
                    <el-input
                      type="text"
                      size="small"
                      v-model="formData.form_modify.contractName"
                      @keydown.enter.native.prevent="handle"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="经办人:">
                    <el-input
                      type="text"
                      size="small"
                      v-model="currentUserName"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6" max-height="60px">
                  <el-form-item
                    label="经办日期:"
                    prop="form_modify.contractDate"
                  >
                    <el-date-picker
                      v-model="formData.form_modify.contractDate"
                      type="date"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                      @change="dateChange"
                    >
                    </el-date-picker>
                    <span class="date-tip" v-show="dateTip"
                      >请注意：所选日期早于当前日期</span
                    >
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="6">
                  <el-form-item label="经办部门:">
                    <el-input
                      type="text"
                      size="small"
                      v-model="formData.form_modify.contractDeptName"
                      disabled
                    >
                    </el-input>
                  </el-form-item>
                </el-col> -->
              </el-row>
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item
                    label="关联项目:"
                    prop="form_modify.projectGoalFlag"
                  >
                    <el-select
                      v-model="formData.form_modify.projectGoalFlag"
                      style="width: 100%"
                      disabled
                    >
                      <el-option label="非项目目的" value="N" />
                      <el-option label="项目目的" value="Y" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item class="correlate">
                    <el-input
                      disabled
                      placeholder="-"
                      v-if="formData.form_modify.projectGoalFlag === 'N'"
                    />
                    <el-input
                      v-else
                      disabled
                      v-model="formData.form_modify.projectName"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否需要付款:">
                    <el-radio-group
                      v-model="formData.form_modify.needPay"
                      disabled
                    >
                      <el-radio label="Y">是</el-radio>
                      <el-radio label="N">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <!-- 是否供方合同 -->
                <el-col :span="6">
                  <el-form-item
                    label="是否供方合同:"
                    prop="supplyStoreFlagChange"
                  >
                    <el-radio-group
                      v-model="formData.supplyStoreFlagChange"
                      @change="changeContractSource"
                    >
                      <el-radio label="1">是</el-radio>
                      <el-radio label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item label="形成方式:">
                    <el-select
                      v-model="formData.form_modify.forType"
                      style="width: 100%"
                    >
                      <template v-for="(item, index) of forTypeList">
                        <el-option
                          :key="index"
                          :label="item.label"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6"  v-if="formData.kgStage">
                      <el-form-item
                  label="所属阶段:"
                  prop="kgStage"
                >
                  <el-select v-model="formData.kgStage" disabled>
                    <el-option label="投资阶段" value="1"/>
                    <el-option label="退出阶段" value="2"/>
                  </el-select>
                </el-form-item>
              </el-col>
                <el-col :span="6" v-if="formData.isRelationFixPj == 1">
                <el-form-item
                  label="具体合同类型:"
                  prop="fixContractType"
                >
                  <el-select v-model="formData.fixContractType" disabled>
                    <el-option label="CapEX合同" value="1"/>
                    <el-option label="OpEX合同" value="2"/>
                    <el-option label="通用合同" value="0"/>
                  </el-select>
                </el-form-item>
              </el-col>

              </el-row>
            </el-row>
          </section>
        </div>

        <!-- 补充协议签约主体 -->
        <div
          class="detail-card detail-card-nopadding"
          v-show="formData.type === 'SIDE_AGREEMENT'"
        >
          <header class="card-header">
            <h4 class="second-title addSub-title">补充协议签约主体</h4>
            <el-tooltip
              effect="dark"
              content="原始合同的签约主体不可修改，只能新增"
              placement="bottom-start"
              :offset="-6"
            >
              <img src="../assets/img/info.jpg" class="sub-icon" />
            </el-tooltip>
          </header>
          <section class="card-content">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item
                  prop="form_agree.addOurCompany"
                  label="我方签约主体:"
                >
                  <el-select
                    multiple
                    v-defaultSelect="[
                      formData.form_agree.addOurCompany,
                      addOurCompanyList_total
                    ]"
                    filterable
                    remote
                    :remote-method="searchAddOurCompany"
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-model="formData.form_agree.addOurCompany"
                    style="width: 100%"
                    ref="addOurCompany"
                    @focus="clearOptions"
                    @change="deleteDisable('1')"
                  >
                    <template v-for="(item, index) of addOurCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                        :disabled="item.disabled"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="对方签约主体:"
                  prop="form_agree.addSupplierCompany"
                >
                  <el-select
                    multiple
                    filterable
                    remote
                    :remote-method="searchAddSupplierCompany"
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-defaultSelect="[
                      formData.form_agree.addSupplierCompany,
                      addSupplierCompanyList
                    ]"
                    @keydown.enter.stop
                    v-model="formData.form_agree.addSupplierCompany"
                    style="width: 100%"
                    ref="addSupplierCompany"
                    @change="deleteDisable('2')"
                  >
                    <div slot="" class="option-wrapper">
                      <template>
                        <el-option
                          v-for="(item) of addSupplierCompanyListNew"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                          :disabled="item.disabled"
                        >
                          <p class="option-title">
                            <span class="option-name" v-maia-ellipsis>{{
                              item.name
                            }}</span>
                            <span :class="['tag', item.type]">{{
                              item.type === "T02"
                                ? "被投方"
                                : item.type === "T03"
                                ? "合作方"
                                : "供方"
                            }}</span>
                          </p>
                        </el-option>
                      </template>
                    </div>
                    <!-- <template v-for="(item, index) of addSupplierCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                        :disabled="item.disabled"
                      />
                    </template> -->
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 解除协议签约主体 -->
        <div class="detail-card" v-if="formData.type === 'RELEASE_AGREEMENT'">
          <header class="card-header describe">
            <h4 class="second-title">签约主体</h4>
          </header>
          <div class="body-wrapper">
            <div class="body-cards">
              <h2>我方签约主体</h2>
              <ul>
                <li
                  v-for="(item, index) in JCXYsupplier.newestOurCompanyList"
                  :key="index"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="body-cards">
              <h2>对方签约主体</h2>
              <ul>
                <li
                  v-for="(item,
                  index) in JCXYsupplier.newestSupplierCompanyList"
                  :key="index"
                >
                  {{ item.name }}
                  <span v-show="item.type === 'T04'" v-supplier>{{
                    item.supplierSourceName || "供方"
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="formData.type === 'SIDE_AGREEMENT'">
          <!-- 补充协议招采信息 -->
          <div class="detail-card detail-card-nopadding">
            <header class="card-header">
              <h4 class="second-title">补充协议招采信息</h4>
            </header>
            <section class="card-content">
              <el-row :gutter="10">
                <el-col :span="18">
                  <el-form-item
                    label="招标/比价/直委:"
                    prop="form_agree.orderMsg"
                  >
                    <el-input
                      type="textarea"
                      size="small"
                      :autosize="{ minRlows: 2, maxRows: 6 }"
                      maxlength="5000"
                      show-word-limit
                      placeholder="请输入内容"
                      v-model="formData.form_agree.orderMsg"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <UploadComponent
                v-model="forminingfileList_agree"
                :fileUploadList="forminingfileList_agree"
                :fileCategory="'RECRUIT'"
                :updateFileList=this.updateFileList
                :label="''"
                :btnName="'上传招采附件'"
                :limit="10"
                :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
              />

            </section>
          </div>
          <!-- 补充协议金额 -->
          <div class="detail-card" v-if="formData.form_agree.needPay === 'Y'">
            <header class="card-header">
              <h4 class="second-title">补充协议金额</h4>
            </header>
            <section class="card-content" style="margin-top:10px;">
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item prop="form_agree.agreementAmountType" class="label-style">
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <div slot="content">
                          <p>金额追加类：指补充协议的协议金额为在主协议基础上追加的金额</p>
                          <p>金额替代类：指补充协议的协议金额会替代主协议的金额</p>
                          <p>金额不变类：指补充协议无需额外增加金额，仍按主协议的金额付款</p>
                        </div>
                        <img src="../assets/img/information.png" class="money-icon" alt="i"/>
                      </el-tooltip>
                      补充协议金额类型:
                    </span>
                    <el-select v-model="formData.form_agree.agreementAmountType" placeholder="请选择类型" style="width: 100%">
                      <el-option label="金额追加类" value="1"></el-option>
                      <el-option label="金额替代类" value="2"></el-option>
                      <el-option label="金额不变类" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <template v-if="formData.form_agree.agreementAmountType!=3">
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item
                    label="补充协议币种:"
                    prop="form_agree.currency"
                  >
                    <el-input
                      disabled
                      v-model="formData.form_agree.currency"
                      v-if="formData.form_agree.needPay === 'Y'"
                    />
                    <el-select
                      v-model="formData.form_agree.currency"
                      style="width: 100%"
                      :clearable="true"
                      v-else
                    >
                      <template v-for="(item, index) of currencyList">
                        <el-option
                          :key="index"
                          :label="item.value"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6" class="form-agree-amount">
                  <el-form-item prop="form_agree.amount" class="label-style">
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        content="指本次补充协议自身的签约金额"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <img src="../assets/img/information.png" class="money-icon" alt=""/>
                      </el-tooltip>
                      本次补充协议金额(含税):
                    </span>
                    <el-input
                      class="amount-input"
                      placeholder="请输入补充协议金额"
                      v-model="formData.form_agree.amount"
                      @input="limitInputAgree($event, 'amount')"
                      @keydown.enter.native.prevent="handle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="form_agree.agreementAfterAmountTax" class="label-style">
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        content="指本次补充协议签署后，主协议累计的合同总金额，即合同最大可付款金额"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <img src="../assets/img/information.png" class="money-icon" alt=""/>
                      </el-tooltip>
                      主协议累计总金额(含税):
                    </span>
                    <el-input
                      class="amount-input"
                      placeholder="请输入主协议累计总金额"
                      v-model="formData.form_agree.agreementAfterAmountTax"
                      disabled
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="发票类型:" prop="form_agree.invoiceType">
                    <el-select
                      v-model="formData.form_agree.invoiceType"
                      style="width: 100%"
                      :popper-append-to-body="false"
                    >
                      <template v-for="(item, index) of invoiceTypeList">
                        <el-option
                          :key="index"
                          :label="item.label"
                          :value="item.code"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="6">
                  <el-form-item
                    label="可抵扣税率:"
                    prop="form_agree.deductibleTaxRate"
                  >
                    <el-input
                      placeholder="请输入可抵扣税率"
                      class="amount-input"
                      v-model="formData.form_agree.deductibleTaxRate"
                      @input="limitInputAgree($event, 'deductibleTaxRate')"
                      @keydown.enter.native.prevent="handle"
                    >
                      <i
                        slot="suffix"
                        :style="{ color: '#323232', paddingRight: 6 + 'px' }"
                      >
                        %
                      </i>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    label="可抵扣税额:"
                    prop="form_agree.deductibleTax"
                  >
                    <el-input
                      disabled
                      class="amount-input"
                      v-model="formData.form_agree.deductibleTax"
                      placeholder="-"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    prop="form_agree.agreementAfterAmountNoTax"
                    class="label-style"
                  >
                    <span slot="label">
                      <el-tooltip
                        effect="dark"
                        content="指本次补充协议签署后，主协议累计的合同总金额（不含抵扣税）"
                        placement="bottom-start"
                        :offset="-10"
                      >
                        <img
                          src="../assets/img/information.png"
                          class="money-icon"
                          alt=""
                        />
                      </el-tooltip>
                      主协议累计总金额(不含抵扣税):
                    </span>
                    <el-input
                      placeholder="-"
                      class="amount-input"
                      v-model="formData.form_agree.agreementAfterAmountNoTax"
                      disabled
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
            </section>
          </div>
          <!-- 费项分摊 -->
          <div class="detail-card" v-if="formData.form_agree.needPay === 'Y'">
            <header class="card-header cost">
              <h4 class="second-title">费项分摊</h4>
              <button class="btn btn-primary" @click.prevent="addCostListAgree">
                <i class="el-icon-circle-plus-outline iconStyle"></i>
                新增
              </button>
            </header>
            <section class="card-content">
              <el-table
                class="cost-table"
                :data="formData.costExpenseList_agree"
                ref="costExpenseList_agree"
                style="width: 100%"
                border
                fit
              >
                <div slot="empty" class="empty-box">
                  <button class="btn" @click.prevent="addCostListAgree">
                    <i class="el-icon-circle-plus-outline"></i>
                  </button>
                  <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
                </div>
                <el-table-column label="责任人" prop="operatorNo">
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="
                        'costExpenseList_agree.' + scope.$index + '.operatorNo'
                      "
                      :rules="costFormRules.operatorNo"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.operatorNo"
                        size="mini"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) of dutyPeoList"
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="费项" prop="costItemsId">
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="
                        'costExpenseList_agree.' + scope.$index + '.costItemsId'
                      "
                      :rules="costFormRules.costItemsId"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsId"
                        size="mini"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) of costDetailList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.FK_KMBM"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="预算分摊部门" prop="costItemsDept">
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="
                        'costExpenseList_agree.' +
                          scope.$index +
                          '.costItemsDept'
                      "
                      :rules="costFormRules.costItemsDept"
                    >
                      <el-select
                        filterable
                        v-model="scope.row.costItemsDept"
                        size="mini"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) of costShareDepartList"
                          :key="index"
                          :label="item.F_CAPTION"
                          :value="item.F_ID"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="分摊金额(含税)"
                  prop="amount"
                  align="right"
                >
                  <template slot-scope="scope">
                    <el-form-item
                      class="cost-card"
                      :prop="
                        'costExpenseList_agree.' + scope.$index + '.amount'
                      "
                      :rules="costFormRules.amount"
                    >
                      <el-input
                        size="mini"
                        v-model="scope.row.amount"
                        class="amount-input"
                        @input="
                          () => {
                            scope.row.amount = limitInput(scope.row.amount);
                          }
                        "
                        @keydown.enter.native.prevent="handle"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="60px" align="left">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        class="operate-btn"
                        type="text"
                        @click="deleteCostAgree(scope.$index, scope.row)"
                      >
                        删除
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </section>
          </div>
        </div>

        <!-- 信息修改相关信息 -->
        <div v-if="formData.type === 'MODIFY'">
          <!-- 签约主体 -->
          <div class="detail-card">
            <header class="card-header">
              <h4 class="second-title">签约主体</h4>
            </header>
            <section class="card-content">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item
                    label="我方签约主体:"
                    prop="form_modify.ourCompany_modify"
                  >
                    <el-select
                      multiple
                      filterable
                      remote
                      :remote-method="searchOurCompany"
                      placeholder="请输入不少于4个字的主体名称进行查询"
                      v-model="formData.form_modify.ourCompany_modify"
                      style="width: 100%"
                      ref="ourCompany_modify"
                      @focus="clearOptions"
                    >
                      <template v-for="item of ourCompanyList">
                        <el-option
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="对方签约主体:"
                    prop="form_modify.supplierCompany_modify"
                  >
                    <el-select
                      multiple
                      filterable
                      placeholder="请输入不少于4个字的主体名称进行查询"
                      remote
                      :remote-method="searchSupplierCompany"
                      v-model="formData.form_modify.supplierCompany_modify"
                      style="width: 100%"
                      ref="supplierCompany_modify"
                    >
                      <div slot="" class="option-wrapper">
                        <template>
                          <el-option
                            v-for="(item) of supplierCompanyListNew"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          >
                          <!-- addSupplierCompanyListNew -->
                            <p class="option-title">
                              <span class="option-name" v-maia-ellipsis>{{
                                item.name
                              }}</span>
                              <span :class="['tag', item.type]">{{
                                item.type === "T02"
                                  ? "被投方"
                                  : item.type === "T03"
                                  ? "合作方"
                                  : "供方"
                              }}</span>
                            </p>
                          </el-option>
                        </template>
                      </div>
                      <!-- <template v-for="(item,index) of supplierCompanyList">
                        <el-option
                          :key="index"
                          :label="item.name"
                          :value="item.id"
                        />
                      </template> -->
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>

          <!-- 需要付款时展示，是否需要付款不可更改 -->
          <div>
            <!-- 招采信息 -->
            <div class="detail-card">
              <header class="card-header">
                <h4 class="second-title">招采信息</h4>
              </header>
              <section class="card-content">
                <el-row :gutter="10">
                  <el-col :span="18">
                    <el-form-item
                      label="招标/比价/直委:"
                      prop="form_modify.orderMsg"
                    >
                      <el-input
                        type="textarea"
                        size="small"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        maxlength="5000"
                        show-word-limit
                        placeholder="请输入招标/比价/直委说明信息"
                        v-model="formData.form_modify.orderMsg"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <UploadComponent
                  v-model="forminingfileList_modify"
                  :fileUploadList="forminingfileList_modify"
                  :fileCategory="'RECRUIT'"
                  :updateFileList=this.updateFileListModify
                  :label="''"
                  :btnName="'上传招采附件'"
                  :limit="10"
                  :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
                />

              </section>
            </div>
            <!-- 合同金额 -->
            <div class="detail-card">
              <header class="card-header">
                <h4 class="second-title">合同金额</h4>
              </header>
              <section class="card-content">
                <el-row :gutter="20">
                  <el-col :span="6">
                    <el-form-item label="币种:" prop="form_modify.currency">
                      <el-select
                        v-model="formData.form_modify.currency"
                        style="width: 100%"
                        :disabled="currencyDisabled"
                        :clearable="true"
                      >
                        <template v-for="(item, index) of currencyList">
                          <el-option
                            :key="index"
                            :label="item.value"
                            :value="item.code"
                          />
                        </template>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="合同金额(含税)(A):"
                      prop="form_modify.amount"
                      class="label-style"
                    >
                      <el-input
                        class="amount-input"
                        placeholder="请输入金额"
                        v-model="formData.form_modify.amount"
                        @input="limitInputModify($event, 'amount')"
                        @keydown.enter.native.prevent="handle"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="不计成本金额(B):"
                      prop="form_modify.costAmount"
                      class="label-style"
                    >
                      <el-input
                        class="amount-input"
                        placeholder="请输入金额"
                        v-model="formData.form_modify.costAmount"
                        @input="limitInputModify($event, 'costAmount')"
                        @keydown.enter.native.prevent="handle"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      prop="form_modify.effContractAmount"
                      class="label-style"
                    >
                      <span slot="label">有效签约金额<br />(C=A-B):</span>
                      <el-input
                        class="amount-input"
                        placeholder="-"
                        v-model="formData.form_modify.effContractAmount"
                        disabled
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="6">
                    <el-form-item
                      label="发票类型:"
                      prop="form_modify.invoiceType"
                    >
                      <el-select
                        v-model="formData.form_modify.invoiceType"
                        style="width: 100%"
                      >
                        <template v-for="(item, index) of invoiceTypeList">
                          <el-option
                            :key="index"
                            :label="item.label"
                            :value="item.code"
                          />
                        </template>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="可抵扣税率:"
                      prop="form_modify.deductibleTaxRate"
                    >
                      <el-input
                        class="amount-input"
                        placeholder="请输入可抵扣税率"
                        v-model="formData.form_modify.deductibleTaxRate"
                        @input="limitInputModify($event, 'deductibleTaxRate')"
                        @keydown.enter.native.prevent="handle"
                      >
                        <i
                          slot="suffix"
                          :style="{ color: '#323232', paddingRight: 6 + 'px' }"
                        >
                          %
                        </i>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="可抵扣税额:"
                      prop="form_modify.deductibleTax"
                    >
                      <el-input
                        class="amount-input"
                        placeholder="-"
                        v-model="formData.form_modify.deductibleTax"
                        disabled
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      prop="form_modify.exContractAmount"
                      class="label-style"
                    >
                      <span slot="label">合同金额<br />(不含抵扣税):</span>
                      <el-input
                        v-model="formData.form_modify.exContractAmount"
                        class="amount-input"
                        placeholder="-"
                        disabled
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <el-row :gutter="20">
                  <el-col :span="6">
                    <el-form-item
                      prop="form_modify.aboveQuotaType"
                      class="label-style"
                    >
                      <span slot="label">可超额付款<br />比例/金额:</span>
                      <el-select
                        v-model="formData.form_modify.aboveQuotaType"
                        style="width: 100%"
                      >
                        <el-option
                          label="按比例"
                          value="RATE"
                        />
                        <el-option
                          label="按金额"
                          value="FIX"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>
                      <div
                        class="toggle"
                        v-if="formData.form_modify.aboveQuotaType == 'RATE'"
                      >
                        <el-input
                          class="amount-input"
                          v-model="formData.form_modify.aboveQuotaScale"
                          placeholder="请输入"
                          @input="limitInputModify($event, 'aboveQuotaScale')"
                          @keydown.enter.native.prevent="handle"
                        >
                          <i
                            slot="suffix"
                            :style="{'color':'#323232','paddingRight': 6+'px'}"
                          > % </i>
                        </el-input>
                      </div>
                      <div
                        class="toggle"
                        v-else
                      >
                        <el-input
                          class="amount-input"
                          v-model="formData.form_modify.aboveQuotaAmount"
                          placeholder="请输入"
                          @input="limitInputModify($event, 'aboveQuotaAmount')"
                          @keydown.enter.native.prevent="handle"
                        >
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row> -->
              </section>
            </div>
            <!-- 费项分摊 -->
            <div
              class="detail-card"
              v-if="formData.form_modify.needPay === 'Y'"
            >
              <header class="card-header cost">
                <h4 class="second-title">费项分摊</h4>
                <button
                  class="btn btn-primary"
                  @click.prevent="addCostListModify"
                >
                  <i class="el-icon-circle-plus-outline iconStyle"></i>
                  新增
                </button>
              </header>
              <section class="card-content">
                <el-table
                  class="cost-table"
                  :data="formData.costExpenseList_modify"
                  ref="costExpenseList_modify"
                  style="width: 100%"
                  border
                  fit
                >
                  <div slot="empty" class="empty-box">
                    <button class="btn" @click.prevent="addCostListModify">
                      <i class="el-icon-circle-plus-outline"></i>
                    </button>
                    <div><span>请点击【新增】按钮，添加费项分摊信息</span></div>
                  </div>
                  <el-table-column label="责任人" prop="operatorNo">
                    <template slot-scope="scope">
                      <el-form-item
                        class="cost-card"
                        :prop="
                          'costExpenseList_modify.' +
                            scope.$index +
                            '.operatorNo'
                        "
                        :rules="costFormRules.operatorNo"
                      >
                        <el-select
                          filterable
                          v-model="scope.row.operatorNo"
                          size="mini"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="(item, index) of dutyPeoList"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="费项" prop="costItemsId">
                    <template slot-scope="scope">
                      <el-form-item
                        class="cost-card"
                        :prop="
                          'costExpenseList_modify.' +
                            scope.$index +
                            '.costItemsId'
                        "
                        :rules="costFormRules.costItemsId"
                      >
                        <el-select
                          filterable
                          v-model="scope.row.costItemsId"
                          size="mini"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="(item, index) of costDetailList"
                            :key="index"
                            :label="item.F_CAPTION"
                            :value="item.FK_KMBM"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="预算分摊部门" prop="costItemsDept">
                    <template slot-scope="scope">
                      <el-form-item
                        class="cost-card"
                        :prop="
                          'costExpenseList_modify.' +
                            scope.$index +
                            '.costItemsDept'
                        "
                        :rules="costFormRules.costItemsDept"
                      >
                        <el-select
                          filterable
                          v-model="scope.row.costItemsDept"
                          size="mini"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="(item, index) of costShareDepartList"
                            :key="index"
                            :label="item.F_CAPTION"
                            :value="item.F_ID"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="分摊金额(含税)"
                    prop="amount"
                    align="right"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        class="cost-card"
                        :prop="
                          'costExpenseList_modify.' + scope.$index + '.amount'
                        "
                        :rules="costFormRules.amount"
                      >
                        <el-input
                          size="mini"
                          v-model="scope.row.amount"
                          class="amount-input"
                          @input="
                            () => {
                              scope.row.amount = limitInput(scope.row.amount);
                            }
                          "
                          @keydown.enter.native.prevent="handle"
                        />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="60px" align="left">
                    <template slot-scope="scope">
                      <div>
                        <el-button
                          class="operate-btn"
                          type="text"
                          @click="deleteCostModify(scope.$index, scope.row)"
                        >
                          删除
                        </el-button>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </section>
            </div>
            <!-- 合同付款约定 -->
            <div
              class="detail-card"
              v-if="formData.form_modify.needPay === 'Y'"
            >
              <header class="card-header describe">
                <h4 class="second-title">合同付款约定</h4>
                <span>请说明首付款、过程付款、尾款的付款条件、时间及金额</span>
              </header>
              <section class="card-content">
                <el-row>
                  <el-col :span="18">
                    <el-form-item
                      label="说明信息:"
                      prop="form_modify.payAgreement"
                    >
                      <el-input
                        type="textarea"
                        size="small"
                        placeholder="请输入内容"
                        :autosize="{ minRows: 2, maxRows: 6 }"
                        maxlength="5000"
                        show-word-limit
                        v-model="formData.form_modify.payAgreement"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </section>
            </div>
          </div>

          <!-- 说明 -->
          <div class="detail-card">
            <header class="card-header describe">
              <h4 class="second-title">合同说明</h4>
              <span>请明确本次合同签订的范围及目的，以供审批人直观审批</span>
            </header>
            <section class="card-content">
              <el-row>
                <el-col :span="18">
                  <el-form-item
                    label="说明信息:"
                    prop="form_modify.contractNote"
                  >
                    <el-input
                      type="textarea"
                      size="small"
                      placeholder="请输入内容"
                      :autosize="{ minRows: 2, maxRows: 6 }"
                      maxlength="5000"
                      show-word-limit
                      v-model="formData.form_modify.contractNote"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>
          <!-- 信息修改原因 -->
          <div class="detail-card">
            <header class="card-header describe">
              <h4 class="second-title">信息修改原因</h4>
              <span>针对本次信息修改的内容进行说明，以供审批人直观审批</span>
            </header>
            <section class="card-content">
              <el-row>
                <el-col :span="18">
                  <el-form-item
                    label="说明信息:"
                    prop="form_modify.infoChangeCause"
                  >
                    <el-input
                      type="textarea"
                      size="small"
                      placeholder="请输入内容"
                      :autosize="{ minRows: 2, maxRows: 6 }"
                      maxlength="5000"
                      show-word-limit
                      v-model="formData.form_modify.infoChangeCause"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </section>
          </div>
          <!-- 文档 -->
          <div class="detail-card  no-bottom-border">
            <header class="card-header">
              <h4 class="second-title">文档</h4>
            </header>
            <section class="card-content">

              <UploadComponent
                v-model="fileList_modify"
                :label="'附件：'"
                :fileCategory="'CONTRACT'"
                :updateFileList=this.updateFileListModify
                :showRemark="true"
                :btnName="'上传文件'"
                :limit="10"
                :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
              />

            </section>
          </div>
        </div>

        <!-- 说明 -->
        <div
          class="detail-card"
          v-if="formData.type !== 'MODIFY' && formData.type !== 'MONEY'"
        >
          <header class="card-header describe">
            <h4 class="second-title" v-if="formData.type === 'SIDE_AGREEMENT'">
              补充协议说明
            </h4>
            <h4
              class="second-title"
              v-if="formData.type === 'RELEASE_AGREEMENT'"
            >
              解除协议说明
            </h4>
            <h4 class="second-title" v-if="formData.type === 'SUBJECT_CHANGE'">
              主体变更说明
            </h4>
          </header>
          <section class="card-content">
            <el-row>
              <el-col :span="18">
                <el-form-item label="说明信息:" prop="agreementNote">
                  <el-input
                    type="textarea"
                    size="small"
                    placeholder="请输入内容"
                    :autosize="{ minRows: 2, maxRows: 6 }"
                    maxlength="5000"
                    show-word-limit
                    v-model="formData.agreementNote"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </section>
        </div>
        <!-- 文档 -->
        <div
          class="detail-card  no-bottom-border"
          v-if="formData.type !== 'MODIFY' && formData.type !== 'MONEY'"
        >
          <header class="card-header">
            <h4 class="second-title">文档</h4>
          </header>
          <section class="card-content">

            <UploadComponent
                v-model="fileList_normal"
                :label="'附件：'"
                :fileCategory="'CONTRACT'"
                :updateFileList=this.updateFileList
                :showRemark="true"
                :btnName="'上传文件'"
                :limit="10"
                :tip="'大小限制＜200M、类型限制(.doc .docx .ppt .pptx .pdf .jpg .jpeg .png .xlsx .xls .rar .zip)'"
              />
          </section>
        </div>
      </el-form>
      <!-- 框架协议类金额修改 -->
      <amount-modify
        v-if="formData.type === 'MONEY'"
        ref="amountModify"
        :invoiceTypeList="invoiceTypeList"
        :currencyList="currencyList"
        :forTypeList="forTypeList"
        :costDetailList="costDetailList"
        :costShareDepartList="costShareDepartList"
        :dutyPeoList="dutyPeoList"
        :currentUserName="currentUserName"
        :subList="subList"
        :modifyData="this.formData.amountModify"
      >
      </amount-modify>
    </section>
    <footer class="footer">
      <div class="btnBox">
        <button
          class="btn btn-primary btn-temporary"
          @click="storage"
          :disabled="storageDisabled"
          :class="storageDisabled ? 'storage_disabled' : ''"
        >
          暂存
        </button>
        <button class="btn btn-primary" @click="approval" id="nextStep">
          下一步
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
// const optionDataFilter = function(
//   keyWord,
//   originList,
//   config = { limit: 4, nameKey: "name" }
// ) {
//   // 搜索字符小于4个字不展示搜索结果
//   const { limit, nameKey } = config;
//   if (keyWord.length < limit) {
//     return [];
//   }
//   let res = originList.filter(element => {
//     let str = element[nameKey].toLocaleLowerCase();
//     let lowKeyWord = keyWord.toLocaleLowerCase();
//     return str.indexOf(lowKeyWord) !== -1;
//   });
//   return res;
// };
import TitleBar from "@/components/common/TitleBar.vue";
import UploadComponent from "@/components/common/UploadComponent.vue"
import utils from "@/utils/index";
import getCurrentUser from "./mixins/getCurrentUser";
import AmountModify from "@/views/amount-modify/Index.vue";
export default {
  name: "creatAgreement",
  components: {
    TitleBar,
    UploadComponent,
    AmountModify
  },
  mixins: [getCurrentUser],
  data() {
    var normalFiles = (rule, value, callback) => {
      let index = this.fileList_normal.findIndex(v => v.status === "success");
      if (index == "-1") {
        callback(new Error("附件不能为空"));
      } else {
        callback();
      }
    };
    var modifyFiles = (rule, value, callback) => {
      let index = this.fileList_modify.findIndex(v => v.status === "success");
      if (index == "-1") {
        callback(new Error("附件不能为空"));
      } else {
        callback();
      }
    };
    return {
      radio: "",
      contractId: "", // 主合同ID
      id: "", // 自动保存草稿之后会返回id
      conInfo: {}, // 主合同信息-用于展示
      storageDisabled: false, // 暂存按钮禁用
      rightNow: "", // 当前日期
      showTips: false, // 变更类型提示

      /**
       * 合同详情
       */
      responseContract: {},

      formData: {
        type: "SIDE_AGREEMENT", // 协议类型
        supplyStoreFlag: "1",
        supplyStoreFlagChange: "1",
        needPay: false, // 主合同是否需要付款
        // 合同变更-补充协议
        form_agree: {
          /**
           * 补充协议金额类型
           * 1-金额追加类
           * 2-金额替代类
           */
          agreementAmountType: "",
          /**
           * 主协议累计总金额(含税)
           */
          agreementAfterAmountTax: "",
          /**
           * 主协议累计总金额(不含抵扣税)
           */
          agreementAfterAmountNoTax: "",
          agreementName: "", // 补充协议名称
          modifyDate: "", // 补充协议经办日期
          orderMsg: "", // 补充协议招采信息
          //amountSign: "plus", // 补充协议合同金额-默认追加
          amount: "", // 补充协议金额
          currency: "", // 补充协议币种
          invoiceType: "", // 补充协议发票类型
          // 主体增加
          addOurCompany: [],
          addSupplierCompany: [],
          aboveQuotaType: "RATE" // 超额付款方式
        },
        costExpenseList_agree: [], // 补充协议-费项分摊
        // 合同变更-主体修改
        form_change: {
          ourCompany_change: [], // 主体变更-现我方签约主体
          supplierCompany_change: [] // 主体变更-现对签约主体
        },
        // 合同变更-信息修改
        form_modify: {
          contractNote: ""
        },
        costExpenseList_modify: [], // 信息修改-费项分摊
        agreementNote: "", // 说明信息
        //attachFileList: [],// 文件上传列表
        amountModify: {}
      },
      currencyDisabled: false, // 信息修改的币种是否可以修改

      agree_dateTip: false, // 补充协议-日期提示
      dateTip: false, // 信息修改日期提示

      fileList_normal: [], // 普通附件-除信息修改

      fileList_modify: [], // 普通附件-信息修改

      forminingfileList_agree: [], // 补充信息-招采附件
      forminingfileList_modify: [], // 信息修改-招采附件

      tempFile: [], // 暂存附件

      // 表单验证
      formRules: {
        type: [{ required: true, message: "请选择协议类型" }],
        "form_agree.agreementAmountType": [{ required: true, message: "请选择补充协议金额类型" }],
        "form_agree.agreementAfterAmountTax": [{ required: true, message: "请输入主协议累计总金额(含税)" }],
        "form_agree.agreementAfterAmountNoTax": [{ required: true, message: "请输入主协议累计总金额(不含抵扣税)" }],
        "form_agree.agreementName": [
          { required: true, message: "请填写补充协议名称" }
        ],
        "form_agree.modifyDate": [
          { required: true, message: "请选择补充协议经办日期" }
        ],
        // "form_agree.amountSign": [{ required: true, message: "请选择金额增加或减少" }],
        "form_agree.amount": [
          { required: true, message: "请根据所选类型输入对应的补充协议金额" }
        ],
        "form_agree.invoiceType": [
          { required: true, message: "请选择发票类型" }
        ],
        "form_agree.currency": [{ required: true, message: "请选择币种" }],
        "form_modify.contractName": [
          { required: true, message: "请输入合同名称" }
        ],
        "form_modify.contractDate": [
          { required: true, message: "请选择经办日期" }
        ],
        "form_modify.currency": [{ required: true, message: "请选择币种" }],
        "form_modify.amount": [{ required: true, message: "请填写合同金额" }],
        "form_modify.invoiceType": [
          { required: true, message: "请选择发票类型" }
        ],
        // "form_modify.contractNote": [{ required: true, message: "请输入内容" }],
        "form_modify.ourCompany_modify": [
          { required: true, message: "请选择我方签约主体" }
        ],
        "form_modify.supplierCompany_modify": [
          { required: true, message: "请选择对方签约主体" }
        ],
        "form_change.ourCompany_change": [
          { type: "array", required: true, message: "请选择现我方签约主体" }
        ],
        "form_change.supplierCompany_change": [
          { type: "array", required: true, message: "请选择现对方签约主体" }
        ],
        attachFileList: [{ required: true, validator: normalFiles }],
        fileListModify: [{ required: true, validator: modifyFiles }],
        supplyStoreFlag: [{ required: false, message: "请选择是否供方合同" }],
        supplyStoreFlagChange: [
          { required: true, message: "请选择是否供方合同" }
        ],
        agreementNote: [{ required: true, message: "请输入内容" }]
      },

      // 费项表单验证
      costFormRules: {
        operatorNo: [{ required: true, message: "请选择责任人" }],
        costItemsId: [{ required: true, message: "请选择费项" }],
        costItemsDept: [{ required: true, message: "请选择费项分摊部门" }],
        amount: [{ required: true, message: "请输入金额" }]
      },
      // 协议类型列表
      agreetypeList: [
        {
          label: "补充协议",
          value: "SIDE_AGREEMENT"
        },
        {
          label: "主体变更",
          value: "SUBJECT_CHANGE"
        },
        {
          label: "解除协议",
          value: "RELEASE_AGREEMENT"
        },
        {
          label: "信息修改",
          value: "MODIFY"
        }
      ],

      // 签约主体列表 -- 主体变更和信息修改使用
      ourCompanyList: [],
      supplierCompanyList: [],

      // 主体集合--主体变更和信息修改使用
      subList: [],

      // 补充协议-主体增加列表
      addOurCompanyList: [],
      addSupplierCompanyList: [],
      addSupplierCompanyListNew: [],
      supplierCompanyListNew: [],
      // 存储主体增加的 主体集合，用于搜索
      addOurCompanyList_total: [],
      // addSupplierCompanyList_total: [],

      //通用-发票类型列表
      invoiceTypeList: [],
      // 通用-形成方式列表
      forTypeList: [],
      // 通用-币种列表
      currencyList: [],

      // 通用-费项责任人选择列表
      dutyPeoList: [],
      // 通用-费项明细选择列表
      costDetailList: [],
      // 通用-费项预算分摊部门列表
      costShareDepartList: [],

      // 全局loading
      loading: null,

      // 暂存主体增加里面不可更改的主体
      tempSub: [],

      // 校验供方合同是否选择供方主体
      checkContractNext: true,
      supplierIdArray: [],
      addSupplierIdArray: [],
      JCXYsupplier: {}, // 接触协议签约主体
      needPayBtnOption: "", // 补充协议是否需要付款按钮 是否支持点击
      amountModify: {} // 框架类协议金额修改表单数据
    };
  },
  methods: {
    radioChangeEvents() {
      this.$forceUpdate();
    },
    handle() {},
    // 是否供方合同
    // 获取所有供方主体id存为数组
    getSupplierIdArray() {
      this.supplierCompanyList.forEach(item => {
        if (item.type === "T04") {
          this.supplierIdArray.push(item.id);
        }
      });
    },
    getAddSupplierIdArray() {
      this.addSupplierCompanyList.forEach(item => {
        if (item.type === "T04") {
          this.addSupplierIdArray.push(item.id);
        }
      });
    },
    // 校验是否供方合同并做处理
    checkContractSource() {
      this.checkContractNext = true;
      if (
        this.formData.supplyStoreFlagChange === "1" &&
        this.formData.form_modify.supplierCompany_modify
      ) {
        this.formData.form_modify.supplierCompany_modify.forEach(item => {
          if (this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false;
          }
        });
      }
      if (
        this.formData.supplyStoreFlag === "1" &&
        this.formData.form_change.supplierCompany_change
      ) {
        this.formData.form_change.supplierCompany_change.forEach(item => {
          if (this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false;
          }
        });
      }
      if (
        this.formData.supplyStoreFlag === "1" &&
        this.formData.form_agree.addSupplierCompany
      ) {
        this.formData.form_agree.addSupplierCompany.forEach(item => {
          if (this.supplierIdArray && this.supplierIdArray.includes(item)) {
            this.checkContractNext = false;
          }
        });
      }
      if (
        this.formData.supplyStoreFlag === "0" ||
        this.formData.supplyStoreFlagChange === "0"
      ) {
        this.checkContractNext = false;
      }
    },
    // 改变合同来源
    changeContractSourceAdd(val = "1") {
      if (val === "0") {
        // this.addSupplierCompanyListNew = [];
        this.addSupplierCompanyList.forEach(item => {
          if (item.type === "T02" || item.type === "T03") {
            this.addSupplierCompanyListNew.push(item);
          }
        });
        if (this.formData.form_agree.addSupplierCompany) {
          this.formData.form_agree.addSupplierCompany.forEach(
            (items, index) => {
              if (
                this.addSupplierIdArray &&
                this.addSupplierIdArray.includes(items)
              ) {
                this.formData.form_agree.addSupplierCompany.splice(index, 1);
              }
            }
          );
        }
      }
      // else {
      //   this.addSupplierCompanyListNew = this.addSupplierCompanyList;
      // }
    },
    changeContractSource(val = "1") {
      if (val === "0") {
        this.supplierCompanyListNew = [];
        this.supplierCompanyList.forEach(item => {
          if (item.type === "T02" || item.type === "T03") {
            this.supplierCompanyListNew.push(item);
          }
        });
        if (this.formData.form_modify.supplierCompany_modify) {
          this.formData.form_modify.supplierCompany_modify.forEach(
            (items, index) => {
              if (
                this.supplierIdArray &&
                this.supplierIdArray.includes(items)
              ) {
                this.formData.form_modify.supplierCompany_modify.splice(
                  index,
                  1
                );
              }
            }
          );
        }
        if (this.formData.form_change.supplierCompany_change) {
          this.formData.form_change.supplierCompany_change.forEach(
            (items, index) => {
              if (
                this.supplierIdArray &&
                this.supplierIdArray.includes(items)
              ) {
                this.formData.form_change.supplierCompany_change.splice(
                  index,
                  1
                );
              }
            }
          );
        }
      }
      // else {
      //   this.supplierCompanyListNew = this.supplierCompanyList;
      // }
    },
    // 补充协议部分币种和金额是否必填
    addMoneyCheck(val) {
      if (val !== "Y") {
        this.$set(this.formRules, "form_agree.invoiceType", [
          { required: false, message: "请选择发票类型" }
        ]);
      } else {
        this.$set(this.formRules, "form_agree.invoiceType", [
          { required: true, message: "请选择发票类型" }
        ]);
      }
      if (
        val !== "Y" &&
        !this.formData.form_agree.amount &&
        !this.formData.form_agree.currency
      ) {
        this.$set(this.formRules, "form_agree.amount", [
          { required: false, message: "请根据所选类型输入对应的补充协议金额" }
        ]);
        this.$set(this.formRules, "form_agree.currency", [
          { required: false, message: "请选择币种" }
        ]);
      } else if (
        val !== "Y" &&
        !this.formData.form_agree.amount &&
        this.formData.form_agree.currency
      ) {
        this.$set(this.formRules, "form_agree.amount", [
          { required: true, message: "请根据所选类型输入对应的补充协议金额" }
        ]);
        this.$set(this.formRules, "form_agree.currency", [
          { required: false, message: "请选择币种" }
        ]);
      } else {
        this.$set(this.formRules, "form_agree.amount", [
          { required: true, message: "请根据所选类型输入对应的补充协议金额" }
        ]);
        this.$set(this.formRules, "form_agree.currency", [
          { required: true, message: "请选择币种" }
        ]);
      }
    },
    // 信息修改部分币种和金额是否必填
    changeMoneyCheck(val) {
      if (val !== "Y") {
        this.$set(this.formRules, "form_modify.invoiceType", [
          { required: false, message: "请选择发票类型" }
        ]);
      } else {
        this.$set(this.formRules, "form_modify.invoiceType", [
          { required: true, message: "请选择发票类型" }
        ]);
      }
      if (
        val !== "Y" &&
        !this.formData.form_modify.amount &&
        !this.formData.form_modify.currency
      ) {
        this.$set(this.formRules, "form_modify.amount", [
          { required: false, message: "请填写合同变更金额" }
        ]);
        this.$set(this.formRules, "form_modify.currency", [
          { required: false, message: "请选择币种" }
        ]);
      } else {
        this.$set(this.formRules, "form_modify.amount", [
          { required: true, message: "请填写合同变更金额" }
        ]);
        this.$set(this.formRules, "form_modify.currency", [
          { required: true, message: "请选择币种" }
        ]);
      }
    },
    // 禁止删除原信息
    deleteDisable(val) {
      let oldVal =
        val === "1"
          ? this.conInfo.ourCompanyId
          : this.conInfo.supplierCompanyId;
      let newVal =
        val === "1"
          ? this.formData.form_agree.addOurCompany
          : this.formData.form_agree.addSupplierCompany;
      if (!newVal.toString().includes(oldVal.toString())) {
        val === "1"
          ? (this.formData.form_agree.addOurCompany = oldVal)
          : (this.formData.form_agree.addSupplierCompany = oldVal);
        this.$message.error("原始合同的签约主体不可修改，只能新增");
      }
    },
    // 过滤上传失败文件
    filterUploadFailsFiles(fileListFilter) {
      // 处理附件
      var _file = [];
      fileListFilter.forEach(item => {
        let index = _file.findIndex(v => v.uid === item.uid);
        if ("success" === item.status && "-1" == index) {
          if (item.partFiles) {
            item.partFiles = "";
          }
          _file = [{ ...item }, ..._file];
        }
      });
      return _file;
    },

    //从子组件获取附件列表-除信息修改普通附件
    uploadFile(params) {
      if (params.length > 0) {
        this.$refs.attach?.$refs.file_rule?.clearValidate();
      }
      params.forEach(item => {
        item.fileCategory = "CONTRACT";
      });
      this.fileList_normal = params;
    },

    //从子组件获取附件列表-信息修改普通附件
    uploadFileModify(params) {
      if (params.length > 0) {
        this.$refs.attach?.$refs.file_rule?.clearValidate();
      }
      params.forEach(item => {
        item.fileCategory = "CONTRACT";
      });
      this.fileList_modify = params;
    },

    //招采类附件列表-信息修改
    uploadForminingFile(params) {
      params.forEach(item => {
        item.fileCategory = "RECRUIT";
      });
      this.forminingfileList_modify = params;
    },

    //招采类附件列表-补充协议
    uploadForminingFileAgree(params) {
      params.forEach(item => {
        item.fileCategory = "RECRUIT";
      });
      this.forminingfileList_agree = params;
    },

    updateFileList(file, fileCategory) {
      if (fileCategory == "CONTRACT") {
        let index = this.fileList_normal.findIndex(f => f.fileId === file.fileId)
        if(index !== -1) {
            return;
        }
        this.fileList_normal = this.fileList_normal.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.fileList_normal = [...this.fileList_normal, file]
      } else {
        let index = this.forminingfileList_agree.findIndex(f => f.fileId == file.fileId)
        if(index !== -1) {
            return;
        }
        this.forminingfileList_agree = this.forminingfileList_agree.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.forminingfileList_agree = [...this.forminingfileList_agree, file]
      }
    },

    updateFileListModify(file, fileCategory) {
      if (fileCategory == "CONTRACT") {
        let index = this.fileList_modify.findIndex(f => f.fileId === file.fileId)
        if(index !== -1) {
            return;
        }
        this.fileList_modify = this.fileList_modify.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.fileList_modify= [...this.fileList_modify, file]
      } else {
        let index = this.forminingfileList_modify.findIndex(f => f.fileId == file.fileId)
        if(index !== -1) {
            return;
        }
        this.forminingfileList_modify = this.forminingfileList_modify.filter(f => {
          if(f.tempFile) {
            return f.tempFile.uid !== file.uid
          } 
          return true;
        })
        this.forminingfileList_modify = [...this.forminingfileList_modify, file]
      }
    },

    // 通过url获取合同id
    getConId() {
      return new Promise(resolve => {
        let id = this.$route.params.id;
        this.contractId = id;
        resolve(id);
      });
    },
    // 根据合同ID 获取合同基本信息
    getConInfo(id) {
      return new Promise(resolve => {
        this.$request
          .post("/contract/queryContractById", { params: { id } })
          .then(res => {
            if (res.code === "200") {
              let data = res.data;
              this.responseContract = data || {}
              this.formData.amountModify = JSON.parse(JSON.stringify(res.data)) ;
              this.formData.isRelationFixPj = res.data.isRelationFixPj
              this.formData.fixContractType = String(res.data.fixContractType)
              this.formData.kgStage = String(res.data.kgStage || '')
              this.formData.needPay = data.needPay;
              this.formData.form_agree.addOurCompany = utils.formatSubId(
                data.ourCompanyId
              );
              this.formData.form_agree.addSupplierCompany = utils.formatSubId(
                data.supplierCompanyId
              );
              this.formData.form_agree.agreementAfterAmountTax = data.agreementAfterAmountTax
              this.formData.form_agree.agreementAfterAmountNoTax = data.agreementAfterAmountNoTax
              // 接触协议签约主体
              this.JCXYsupplier = {
                newestOurCompanyList: data.newestOurCompanyList,
                newestSupplierCompanyList: data.newestSupplierCompanyList
              };
              // 处理主体增加 搜索主体
              this.addOurCompanyList = res.data.newestOurCompanyList
              this.supplierCompanyListNew = res.data.newestSupplierCompanyList

              // this.addOurCompanyList = this.addOurCompanyList_total.filter(
              //   element => {
              //     return (
              //       this.formData.form_agree.addOurCompany.indexOf(
              //         element.id
              //       ) !== -1
              //     );
              //   }
              // );
              // this.supplierCompanyListNew = this.addOurCompanyList_total.filter(
              //   element => {
              //     return (
              //       this.formData.form_agree.addOurCompany.indexOf(
              //         element.id
              //       ) !== -1
              //     );
              //   }
              // );

              this.addOurCompanyList.forEach(item => {
                item.disabled = true;
              });

              this.addSupplierCompanyList.forEach(item => {
                if (
                  this.formData.form_agree.addSupplierCompany.includes(item.id)
                ) {
                  item.disabled = true;
                }
              });
              // 是否供方合同
              this.formData.supplyStoreFlag = data.supplyStoreFlag || "0";
              this.formData.supplyStoreFlagChange = data.supplyStoreFlag || "0";
              this.changeContractSource(this.formData.supplyStoreFlag);
              this.changeContractSourceAdd(this.formData.supplyStoreFlag);
              // this.addSupplierCompanyList = (this.addSupplierCompanyList_total).filter(element=>{
              //   return this.formData.form_agree.addSupplierCompany.indexOf(element.id)!==-1
              // })
              // this.addSupplierCompanyList.forEach(item =>{
              //   item.disabled = true
              // })

              // 合同变更 仅用于展示的主合同信息
              this.conInfo = {
                contractName: data.contractName,
                contractNo: data.contractNo,
                contractTypeValue: data.contractTypeValue,
                operatorName: data.operatorName,
                ourCompany: utils.formatSubName(data.ourCompany),
                supplierCompany: utils.formatSubName(data.supplierCompany),
                ourCompanyId: utils.formatSubId(data.ourCompanyId),
                supplierCompanyId: utils.formatSubId(data.supplierCompanyId)
              };

              if (data.contractPayInfoVos && data.contractPayInfoVos.length) {
                this.currencyDisabled = true;
              } else {
                this.currencyDisabled = false;
              }

              // 合同变更-补充协议
              // this.formData.form_agree.amount = data.amount
              this.formData.form_agree.currency = data.currency;
              this.formData.form_agree.needPay = data.needPay;
              this.needPayBtnOption = data.needPay;
              this.addMoneyCheck(this.formData.form_agree.needPay);
              // 信息修改所需要的所有合同信息
              this.formData.form_modify = {
                contractName: data.contractName,
                contractNo: data.contractNo,
                contractType: data.contractType,
                contractTypeValue: data.contractTypeValue,
                operatorName: data.operatorName,
                contractDate: data.contractDate,
                forType: data.forType,
                needPay: data.needPay,
                projectGoalFlag: data.projectGoalFlag,
                projectId: utils.formatSubName(data.projectId),
                projectName: utils.formatSubName(data.projectName),
                ourCompany_modify: utils.formatSubId(data.ourCompanyId),
                supplierCompany_modify: utils.formatSubId(
                  data.supplierCompanyId
                ),
                orderMsg: data.orderMsg,
                currency: data.currency,
                amount: data.contractAmount,
                costAmount: data.costAmount,
                effContractAmount: data.effContractAmount,
                invoiceType: data.invoiceType,
                deductibleTax: data.deductibleTax,
                exContractAmount: data.exContractAmount,
                aboveQuotaType: data.aboveQuotaType,
                aboveQuotaAmount: data.aboveQuotaAmount,
                payAgreement: data.payAgreement,
                contractNote: data.contractNote
              };

              this.changeMoneyCheck(this.formData.form_modify.needPay);
              // 处理 可超额付款比例和可抵扣税率
              // data.aboveQuotaScale? this.formData.form_modify.aboveQuotaScale = data.aboveQuotaScale * 100 : this.formData.form_modify.aboveQuotaScale = ''
              // data.deductibleTaxRate? this.formData.form_modify.deductibleTaxRate = (data.deductibleTaxRate * 100).toFixed(2) : this.formData.form_modify.deductibleTaxRate = ''
              data.aboveQuotaScale
                ? this.$set(
                    this.formData.form_modify,
                    "aboveQuotaScale",
                    utils.BigNumberCacl(
                      data.aboveQuotaScale,
                      "100",
                      "*",
                      "noFixed"
                    )
                  )
                : this.$set(this.formData.form_modify, "aboveQuotaScale", "");
              data.deductibleTaxRate
                ? this.$set(
                    this.formData.form_modify,
                    "deductibleTaxRate",
                    (data.deductibleTaxRate * 100).toFixed(2)
                  )
                : this.$set(this.formData.form_modify, "deductibleTaxRate", "");
              // if (data.deductibleTaxRate) {
              //   this.formData.form_modify.deductibleTaxRate = (data.deductibleTaxRate * 100).toFixed(2)
              // }
              // 信息修改的主体
              this.ourCompanyList = this.subList.T01.filter(element => {
                return (
                  this.formData.form_modify.ourCompany_modify.indexOf(
                    element.id
                  ) !== -1
                );
              });

              this.addSupplierCompanyListNew = this.supplierCompanyListNew = this.subList.T02.filter(element=>{
                return this.formData.form_modify.supplierCompany_modify.indexOf(element.id)!==-1
              })
              // 信息修改-费项分摊
              this.formData.costExpenseList_modify = data.costExpenseList;

              // 拆分附件 回显到信息修改
              data.attachFileList.forEach((item, index) => {
                item.status = "success";
                item.uid = "a" + index;
              });
              let fileList = data.attachFileList;
              this.forminingfileList_modify = fileList.filter(item => {
                return item.fileCategory == "RECRUIT";
              });

              this.fileList_modify = fileList.filter(item => {
                return item.fileCategory == "CONTRACT";
              });
            }
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            resolve(res.data);
          });
      });
    },

    // 获取当前日期
    getRightNowDate() {
      let nowDate = new Date();
      let y = nowDate.getFullYear();
      let m = (nowDate.getMonth() + 1).toString();
      let d = nowDate.getDate().toString();

      m = m.length == 1 ? "0" + m : m;
      d = d.length == 1 ? "0" + d : d;

      this.rightNow = y + "-" + m + "-" + d;
      this.formData.form_agree.modifyDate = this.rightNow;
    },

    // 信息修改-经办日期修改提示
    dateChange(val) {
      if (val < this.rightNow) {
        this.dateTip = true;
      } else {
        this.dateTip = false;
      }
    },

    // 补充协议-经办日期修改提示
    agreeDateChange(val) {
      if (val < this.rightNow) {
        this.agree_dateTip = true;
      } else {
        this.agree_dateTip = false;
      }
    },

    // 通用-获取主体列表
    getSubList() {
      return new Promise((resolve, reject) => {
        this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
          if (res.code === "200") {
            let data = res.data;

            this.subList = JSON.parse(JSON.stringify(data));

            this.ourCompanyList = JSON.parse(JSON.stringify(data.T01))
            this.supplierCompanyList = JSON.parse(JSON.stringify(data.T02));
            this.getSupplierIdArray();
            this.addOurCompanyList_total = JSON.parse(JSON.stringify(data.T01));
            this.addSupplierCompanyList_total = JSON.parse(JSON.stringify(data.T02))
            this.addSupplierCompanyList = JSON.parse(JSON.stringify(data.T02));
            this.getAddSupplierIdArray();

            // 主体增加的情况下，默认值增加disabled属性
            this.addOurCompanyList_total.forEach(item => {
              if (this.formData.form_agree.addOurCompany.includes(item.id)) {
                item.disabled = true;
              }
            });
            // this.addSupplierCompanyList_total.forEach(item => {
            //   if(this.formData.form_agree.addSupplierCompany.includes(item.id)) {
            //     item.disabled = true
            //   }
            // })
            this.addSupplierCompanyList.forEach(item => {
              if (
                this.formData.form_agree.addSupplierCompany.includes(item.id)
              ) {
                item.disabled = true;
              }
            });
            resolve();
          }
          reject("获取主体失败，请刷新重试");
        });
      });
    },

    // 通用-获取费项列表责任人
    getCostDutePeo() {
      this.$request.post("/employee/queryEmployeeAll?scope=").then(res => {
        if (res.code === "200") {
          this.dutyPeoList = res.data;
        }
      });
    },
    // 通用-获取费项预算部门
    getCostDep() {
      this.$request.post("/cost/queryCostItemDept").then(res => {
        if (res.code === "200") {
          this.costShareDepartList = res.data;
        }
      });
    },
    // 通用-获取费项选择列表
    getCostList() {
      this.$request.post("/cost/queryCostItem").then(res => {
        if (res.code === "200") {
          this.costDetailList = res.data;
        }
      });
    },

    // 补充协议-增加费项行
    addCostListAgree() {
      this.formData.costExpenseList_agree.push({});
    },
    // 补充协议-删除费项行
    deleteCostAgree(index, row) {
      this.formData.costExpenseList_agree.splice(index, 1);
    },

    // 信息修改-增加费项行
    addCostListModify() {
      this.formData.costExpenseList_modify.push({});
    },

    // 信息修改-删除费项行
    deleteCostModify(index, row) {
      this.formData.costExpenseList_modify.splice(index, 1);
    },

    // 处理金额输入框的校验
    limitInput(value, name) {
      return this.$limitRule(value, name);
    },

    // 补充信息-处理金额输入框的校验
    limitInputAgree(value, name) {
      return this.$limitRule(value, name, this.formData.form_agree);
    },

    // 信息修改-处理金额输入框的校验
    limitInputModify(value, name) {
      return this.$limitRule(value, name, this.formData.form_modify);
    },

    // 类型变更时处理补充协议-签约主体的数据处理
    typeChange(val) {
      if (this.formData.form_agree.addOurCompany.length !== 0) {
        this.tempSub = JSON.parse(
          JSON.stringify(this.formData.form_agree.addOurCompany)
        );
      }

      if (val !== "SIDE_AGREEMENT") {
        this.formData.form_agree.addOurCompany.length = 0;
      } else {
        this.formData.form_agree.addOurCompany = JSON.parse(
          JSON.stringify(this.tempSub)
        );
      }
      setTimeout(() => {
        this.$refs['supplierCompany_change'].selectedLabel = ''
      }, 0);
      this.$forceUpdate()
    },

    // 下一步保存数据
    approval() {
      let isCheck = false;
      this.checkContractSource();
      if (this.checkContractNext) {
        this.$message.error("供方合同请至少选择一个供方主体");
        return;
      }
      if (
        this.formData.type === "SIDE_AGREEMENT" &&
        this.formData.form_agree.agreementName.trim() === ""
      ) {
        this.$message({
          type: "error",
          message: "合同变更名称不能为空"
        });
        return;
      }
      this.$refs["formData"].validate(valid => {
        isCheck = valid;
        if (valid) {
          // 当变更类型为框架类协议金额修改时，验证子表单必填项是否填写
          if (this.formData.needPay === "Y" && this.formData.type === "MONEY") {
            const result = this.$refs.amountModify.submitForm();
            this.amountModify = result;
            // result.flag 为true 验证通过，false 不通过, 不通过直接返回
            if (!result.flag) {
              this.$message.warning("请填写必填项！");
              return;
            }
          }
          // 打开全局loading
          this.loading = this.$loading({
            fullscreen: true,
            background: "rgba(255, 255, 255, 0.8)",
            text: "正在保存数据..."
          });

          // 发起接口调用
          this.saveAgree("agree")
            .then(res => {
              this.$message({
                type: "success",
                message: "保存成功，即将跳转审批页面",
                duration: 1500,
                onClose: () => {
                  // 跳转BPM 页面
                  var pWin = window.open("_blank");
                  pWin.location = res;
                  window.opener = null;
                  window.open("about:blank", "_top").close();
                }
              });
            })
            .catch(error => {
              // 关闭全局loading
              if (this.loading) {
                this.loading.close();
              }
              this.$message.error(error);
            });
        } else {
          this.$message({
            type: "error",
            message: "请填写必填项",
            duration: 1500
          });
        }
      });
    },

    // 保存调用接口
    saveAgree(type) {
      let params = {}; // 用于传参的对象
      let totalFileList = []; // 用于接收页面附件的数组

      // 如果为主体变更，处理主体list结构 赋值给入参params
      if (this.formData.type === "SUBJECT_CHANGE") {
        params = {};
        // 处理主体
        params.ourCompany = this.$formatSubList(
          "ourCompany_change",
          this.formData.form_change
        );
        params.supplierCompany = this.$formatSubList(
          "supplierCompany_change",
          this.formData.form_change
        );
        totalFileList = [...this.fileList_normal];
      }

      // 如果为解除协议，入参只有必填项
      if (this.formData.type === "RELEASE_AGREEMENT") {
        params = {};

        totalFileList = [...this.fileList_normal];
      }

      // 如果为合同变更，判断是否需要付款，处理入参
      if (this.formData.type === "SIDE_AGREEMENT") {
        // 如果不需要付款，只需要传入名称和不需要付款字段
        // if(this.formData.form_agree.needPay === 'N') {
        //   params = {}
        //   // 处理补充协议名称
        //   params.agreementName = this.formData.form_agree.agreementName.trim()
        //   params.needPay = "N"
        //   totalFileList = [...this.fileList_normal]
        //   params.modifyDate = this.formData.form_agree.modifyDate
        // }

        // 如果需要付款，需要补充协议的所有相关信息
        // if(this.formData.form_agree.needPay === 'Y') {
        if (true) {
          // 将页面数据赋值给params
          params = Object.assign(this.formData.form_agree);
          // 处理补充协议名称-两头去空格
          params.agreementName = params.agreementName.trim();

          // 处理附件 招采附件和普通附件
          totalFileList = [
            ...this.fileList_normal,
            ...this.forminingfileList_agree
          ];

          // 处理费项
          if (this.formData.costExpenseList_agree.length !== 0) {
            let costList = this.formData.costExpenseList_agree;
            costList.forEach(item => {
              this.costDetailList.forEach(it => {
                if (it.FK_KMBM === item.costItemsId) {
                  item.costItemsName = it.F_CAPTION;
                }
              });
              this.costShareDepartList.forEach(it => {
                if (it.F_ID === item.costItemsDept) {
                  item.costItemsDeptName = it.F_CAPTION;
                }
              });
            });

            // 赋值 费项列表
            params.costExpenseList = costList;
          }
        }

        // 处理主体
        params.ourCompany = this.$formatSubList(
          "addOurCompany",
          this.formData.form_agree
        );
        params.supplierCompany = this.$formatSubList(
          "addSupplierCompany",
          this.formData.form_agree
        );
      }

      // 如果为信息修改
      if (this.formData.type == "MODIFY") {
        // 将页面上信息修改的信息赋值给params
        params = Object.assign(this.formData.form_modify);
        // 处理合同名称-两头去空格
        params.contractName = params.contractName.trim();

        // 处理附件 招采附件和普通附件
        totalFileList = [
          ...this.fileList_modify,
          ...this.forminingfileList_modify
        ];

        // 处理费项
        if (this.formData.costExpenseList_modify.length !== 0) {
          let costList = this.formData.costExpenseList_modify;
          costList.forEach(item => {
            this.costDetailList.forEach(it => {
              if (it.FK_KMBM === item.costItemsId) {
                item.costItemsName = it.F_CAPTION;
              }
            });
            this.costShareDepartList.forEach(it => {
              if (it.F_ID === item.costItemsDept) {
                item.costItemsDeptName = it.F_CAPTION;
              }
            });
          });

          // 赋值 费项列表
          params.costExpenseList = costList;
        }

        // 处理主体
        params.ourCompany = this.$formatSubList(
          "ourCompany_modify",
          this.formData.form_modify
        );
        params.supplierCompany = this.$formatSubList(
          "supplierCompany_modify",
          this.formData.form_modify
        );

        // 处理关联项目
        params.relevanceProjectInfo = params.projectId;
      }

      if (this.formData.type == "MONEY") {
        params = { ...this.amountModify };
        totalFileList = params.attachFileList;
        params.relevanceProjectInfo = params.projectId;
        delete params.flag;
      }

      params.contractId = this.contractId;
      params.type = this.formData.type;
      params.agreementNote = this.formData.agreementNote; // 除信息修改 的说明
      params.attachFileList = [...this.filterUploadFailsFiles(totalFileList)];

      if (type === "draft") {
        params.auditStatus = "draft";
      } else {
        params.auditStatus = "";
      }
      if (this.id) {
        params.id = this.id;
      }

      params.supplyStoreFlag = this.formData.supplyStoreFlag;
      if (this.formData.type == "MODIFY") {
        params.supplyStoreFlag = this.formData.supplyStoreFlagChange;
      }
      if(this.formData.form_agree.agreementAmountType == 3) {
        params = { ...params }
        params.amount = 0
        params.invoiceType = '11'
      }

      /**
       * 暂存状态-需改为原始值
       * 主协议累计总金额(含税)
       * 主协议累计总金额(不含抵扣税)
       * [原因]编辑回显时，会重复计算
       */
       if (type === "draft") {
        params.agreementAfterAmountTax = this.responseContract.agreementAfterAmountTax;
        params.agreementAfterAmountNoTax = this.responseContract.agreementAfterAmountNoTax;
      }
      
      return new Promise(resolve => {
        this.$request
          .post("/contractAgreement/saveAgreement", { params })
          .then(res => {
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            this.storageDisabled = false;
            if (res.code === "200" && res.data) {
              resolve(res.data);
            }
          })
          .catch(error => {
            // 关闭全局loading
            if (this.loading) {
              this.loading.close();
            }
            this.$message.error(error);
          });
      });
    },
    //暂存
    storage() {
      if (
        this.formData.type === "SIDE_AGREEMENT" &&
        !this.formData.form_agree.agreementName.trim()
      ) {
        this.$message.error("补充协议名称不能为空");
        return;
      }
      if (this.formData.needPay === "Y" && this.formData.type === "MONEY") {
        const result = this.$refs.amountModify.storageForm();
        this.amountModify = result;
      }
      this.storageDisabled = true;
      this.saveAgree("draft").then(res => {
        this.id = res;
        this.$message({
          type: "success",
          message: "暂存成功",
          duration: 1000
        });
      });
    },

    // 查看主合同
    goMainCon() {
      this.$open({
        name: "conDetail",
        path: `/condetail/${this.contractId}`,
        params: {
          id: this.contractId
        }
      });
    },

    // 远程搜索我方主体-主体变更/信息修改
    searchOurCompany(keyWords) {
      this.ourCompanyList = this.$optionDataFilter(keyWords, this.subList.T01);
    },

    // 远程搜索对方主体-主体变更/信息修改
    searchSupplierCompany(keyWords){
      this.supplierCompanyListNew = this.$optionDataFilter(keyWords,this.subList.T02)
    },

    // 远程搜索我方主体-补充协议主体增加
    searchAddOurCompany(keyWords) {
      this.addOurCompanyList = this.$optionDataFilter(
        keyWords,
        this.addOurCompanyList_total
      );
    },

    // 远程搜索对方主体-补充协议主体增加
    searchAddSupplierCompany(keyWords){
      let res = this.$optionDataFilter(keyWords,this.addSupplierCompanyList_total)
      this.$nextTick(()=> {
        let arr = [...res]
        this.addSupplierCompanyListNew = arr
        this.$forceUpdate()
      })
    },

    clearOptions() {
      this.addOurCompanyList = [];
      this.addSupplierCompanyList = []
      this.ourCompanyList = [];
      this.supplierCompanyList = []
    }
  },
  computed: {
    listenChange() {
      const { form_agree, form_modify, type } = this.formData;
      return { form_agree, form_modify, type };
    },
    changeType() {
      let changeTypeList = [];
      if (this.formData.needPay === "Y") {
        changeTypeList = [
          ...this.agreetypeList,
          {
            label: "框架类协议金额修改",
            value: "MONEY"
          }
        ];
      } else {
        changeTypeList = [...this.agreetypeList];
      }
      return changeTypeList;
    }
  },
  watch: {
    listenChange: {
      handler(val, oldval) {
        let { amount = 0 } = val.form_agree || {}

        /**
         * 主协议累计总金额(含税)
         * 保留2位小数
         */
        this.formData.form_agree.agreementAfterAmountTax = utils.BigNumberCacl(val.form_agree.agreementAfterAmountTax || 0, 0, '-', 2)

        /**
         * 金额不变类
         */
        if (val.form_agree.agreementAmountType === '3') {
          amount = 0
          this.formData.form_agree.amount = utils.BigNumberCacl(0, 0, '-', 2)
          this.formData.form_agree.agreementAfterAmountTax = utils.BigNumberCacl(this.responseContract.agreementAfterAmountTax || 0, amount || 0, '+', 2)
        }

        /**
         * 金额追加类
         * 主协议累计总金额(含税)
         * = 主协议累计总金额（终表金额）+本次用户填写的补充协议金额(含税)
         */
        if (val.form_agree.agreementAmountType === '1') {
          this.formData.form_agree.agreementAfterAmountTax = utils.BigNumberCacl(this.responseContract.agreementAfterAmountTax || 0, amount || 0, '+', 2)
        }

        /**
         * 金额替代类
         * 主协议累计总金额(含税)
         * =本次用户填写的补充协议金额(含税)
         */
        if (val.form_agree.agreementAmountType === '2') {
          this.formData.form_agree.agreementAfterAmountTax = utils.BigNumberCacl(amount || 0, 0, '+', 2)
        }

        //  补充协议 - 合同变更金额,合同变更金额（不含税）,可抵扣税率
        if (
          amount !== undefined &&
          amount !== ""
        ) {
          if (val.form_agree.deductibleTaxRate) {
            let middle = utils.BigNumberCacl(
              this.formData.form_agree.agreementAfterAmountTax,
              1 + val.form_agree.deductibleTaxRate / 100,
              "/",
              "noFixed"
            );
            let tax = utils.BigNumberCacl(
              val.form_agree.deductibleTaxRate / 100,
              middle,
              "*"
            );
            this.formData.form_agree.deductibleTax = tax;

            this.formData.form_agree.exContractAmount = utils.BigNumberCacl(
              this.formData.form_agree.agreementAfterAmountTax,
              this.formData.form_agree.deductibleTax,
              "-"
            );
          } else {
            this.formData.form_agree.exContractAmount = utils.BigNumberCacl(
              this.formData.form_agree.agreementAfterAmountTax,
              "0",
              "-"
            );
            this.formData.form_agree.deductibleTax = null;
          }
        } else {
          this.formData.form_agree.exContractAmount = null;
          this.formData.form_agree.deductibleTax = null;
        }

        // 信息修改 - 合同变更金额,合同变更金额（不含税）,可抵扣税率
        if (
          val.form_modify.amount !== undefined &&
          val.form_modify.amount !== ""
        ) {
          // 计算有效签约金额
          if (val.form_modify.costAmount) {
            if (
              parseFloat(val.form_modify.amount) >=
              parseFloat(val.form_modify.costAmount)
            ) {
              this.formData.form_modify.effContractAmount = utils.BigNumberCacl(
                this.formData.form_modify.amount,
                this.formData.form_modify.costAmount,
                "-"
              );
            } else {
              this.$message.warning("有效签约金额不能为负数");
              this.formData.form_modify.effContractAmount = utils.BigNumberCacl(
                this.formData.form_modify.amount,
                "0",
                "-"
              );
              this.formData.form_modify.costAmount = "";
            }
          } else {
            this.formData.form_modify.effContractAmount = utils.BigNumberCacl(
              this.formData.form_modify.amount,
              "0",
              "-"
            );
          }

          //   // 计算可抵扣税额 合同金额（不含抵扣税）
          if (val.form_modify.deductibleTaxRate) {
            // let tax = this.formData.form_modify.amount / (1 +(val.form_modify.deductibleTaxRate / 100)) * (val.form_modify.deductibleTaxRate / 100)
            let middles = utils.BigNumberCacl(
              this.formData.form_modify.amount,
              1 + val.form_modify.deductibleTaxRate / 100,
              "/"
            );
            let tax = utils.BigNumberCacl(
              val.form_modify.deductibleTaxRate / 100,
              middles,
              "*"
            );
            this.formData.form_modify.deductibleTax = tax;
            this.formData.form_modify.exContractAmount = utils.BigNumberCacl(
              this.formData.form_modify.amount,
              this.formData.form_modify.deductibleTax,
              "-"
            );
          } else {
            // this.formData.form_modify.exContractAmount = utils.numSub(this.formData.form_modify.amount, 0)
            this.formData.form_modify.exContractAmount = utils.BigNumberCacl(
              this.formData.form_modify.amount,
              "0",
              "-"
            );
            this.formData.form_modify.deductibleTax = null;
          }
        } else {
          this.formData.form_modify.effContractAmount = null;
          this.formData.form_modify.exContractAmount = null;
          this.formData.form_modify.deductibleTax = null;
        }

        // 切换类型导致主体表获取不到信息
        if (val.type === "SUBJECT_CHANGE") {
          this.ourCompanyList = this.subList.T01.filter(item => {
            return (
              this.formData.form_change.ourCompany_change.indexOf(item.id) !==
              -1
            );
          });

          this.supplierCompanyListNew = this.subList.T02.filter(item => {
            return this.formData.form_change.supplierCompany_change.indexOf(item.id) !== -1
          })
        }

        if (val.type === "MODIFY") {
          this.ourCompanyList = this.subList.T01.filter(item => {
            return (
              this.formData.form_modify.ourCompany_modify.indexOf(item.id) !==
              -1
            );
          });

          this.supplierCompanyListNew = this.subList.T02.filter(item => {
            return this.formData.form_modify.supplierCompany_modify.indexOf(item.id) !== -1
          })
        }

        // 主协议累计总金额(不含抵扣税)
        if (![undefined, '', null].includes(amount)) {
          this.formData.form_agree.agreementAfterAmountNoTax = utils.BigNumberCacl(this.formData.form_agree.agreementAfterAmountTax || 0, this.formData.form_agree.deductibleTax || 0, '-', 2)
        } else {
          this.formData.form_agree.agreementAfterAmountNoTax = utils.BigNumberCacl(this.responseContract.agreementAfterAmountNoTax || 0, 0, '-', 2)
        }
      },
      deep: true
    },
    "formData.form_agree.currency": function(val) {
      if (this.formData.form_agree.needPay === "N") {
        if (val) {
          this.$set(this.formRules, "form_agree.amount", [
            { required: true, message: "请根据所选类型输入对应的补充协议金额" }
          ]);
        } else {
          this.$set(this.formRules, "form_agree.amount", [
            { required: false, message: "请根据所选类型输入对应的补充协议金额" }
          ]);
        }
      }
    },
    "formData.form_agree.amount": function(val) {
      if (this.formData.form_agree.needPay === "N") {
        if (val) {
          this.$set(this.formRules, "form_agree.currency", [
            { required: true, message: "请选择币种" }
          ]);
        } else {
          this.$set(this.formRules, "form_agree.currency", [
            { required: false, message: "请选择币种" }
          ]);
        }
      }
    },
    "formData.form_modify.currency": function(val) {
      if (this.formData.form_modify.needPay === "N") {
        if (val) {
          this.$set(this.formRules, "form_modify.amount", [
            { required: true, message: "请填写合同变更金额" }
          ]);
        } else {
          this.$set(this.formRules, "form_modify.amount", [
            { required: false, message: "请填写合同变更金额" }
          ]);
        }
      }
    },
    "formData.form_modify.amount": function(val) {
      if (this.formData.form_modify.needPay === "N") {
        if (val) {
          this.$set(this.formRules, "form_modify.currency", [
            { required: true, message: "请选择币种" }
          ]);
        } else {
          this.$set(this.formRules, "form_modify.currency", [
            { required: false, message: "请选择币种" }
          ]);
        }
      }
    }
  },

  created() {
    this.loading = this.$loading({
      fullscreen: true,
      background: "rgba(255, 255, 255, 0.8)",
      text: "正在加载数据..."
    });
    this.currencyList = JSON.parse(localStorage.getItem("currencyList"));
    this.invoiceTypeList = JSON.parse(localStorage.getItem("invoiceTypeList"));
    this.forTypeList = JSON.parse(localStorage.getItem("forTypeList"));

    this.getSubList()
      .then(() => {
        this.getConId().then(id => {
          this.getConInfo(id);
        });
      })
      .catch(error => {
        // 关闭全局loading
        if (this.loading) {
          this.loading.close();
        }
        this.$message.error(error);
      });
    this.getCurrentUser();
    this.getRightNowDate();
    this.getCostDutePeo();
    this.getCostDep();
    this.getCostList();
  }
};
</script>
<style lang="less" scoped>
  /deep/.form-agree-amount {
    .el-form-item__error {
      position: relative;
      line-height: 14px;
    }
  }
.option-wrapper {
  ::v-deep .el-select-dropdown__item.selected::after {
    top: 0;
    color: #997236;
  }
  .option-title {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    span.option-name {
      display: inline-block;
      width: 336px;
      color: #606266;
      font-size: 14px;
    }
    .tag {
      width: 48px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      background: #f7ebd4;
      border-radius: 2px;
      font-size: 10px;
      &.T02 {
        color: #7aa2f4 !important;
        background: #eef3fd;
      }
      &.T03 {
        color: #997236 !important;
        background: #f7ebd4;
      }
      &.T04 {
        color: #50ac94 !important;
        background: #e0f7f1;
      }
    }
  }
}

.page-box {
  .list-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px solid #eaeaea;
  }
  .detail-card {
    .card-header {
      .addSub-title {
        display: inline-block;
      }
      .sub-icon {
        display: inline-block;
        margin-left: 8px;
        margin-bottom: -2px;
        cursor: pointer;
      }
    }
    .card-content {
      // .el-row{
      //   height: 50px;
      //   .el-col{
      //     height: 50px;
      //   }
      // }
      .money-icon {
        // vertical-align: text-bottom;
        vertical-align: text-top;
        cursor: pointer;
      }
      .sign-col {
        display: flex;

        .select-sign {
          width: 72px !important;
        }

        .select-sign /deep/ .el-input__inner {
          border-right: 0 !important;
          border-radius: 4px 0 0 4px;
          background-color: #f5f5f5;
        }

        .select-sign /deep/ .el-select__caret {
          color: #323232;
          font-size: 12px;
        }

        .amount-vaild {
          flex: 1;

          .agree-amount {
            .amount-input /deep/ .el-input__inner {
              border-left: 0 !important;
              border-radius: 0 4px 4px 0;
            }
          }
        }

        .amount-vaild /deep/ .el-form-item__content {
          margin-left: 0 !important;
        }
      }

      /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #efd193;
        background: #efd193;

        &::after {
          background-color: #323232;
          border-radius: 50%;
        }
      }

      .el-radio__label {
        color: #323232;
      }
      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #323232;
      }

      .correlate {
        margin-left: -120px;
      }

      .date-tip {
        font-size: 12px;
        position: relative;
        top: -8px;
        color: #808898;
      }

      .toggle {
        position: absolute;
        left: -120px;
      }

      .tip-icon {
        margin: 0 2px;
        cursor: pointer;
      }
      .triangle {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 7px solid rgba(50, 50, 50, 0.95);
        position: absolute;
        bottom: -1px;
        left: -28px;
        z-index: 99;
      }
      .tips-list {
        box-sizing: border-box;
        width: 450px;
        padding: 6px 12px;
        color: #fff;
        background: rgba(50, 50, 50, 0.95);
        border-radius: 4px;
        position: absolute;
        top: 34px;
        left: -70px;
        z-index: 99;
      }
    }
    .describe {
      h4,
      span {
        display: inline-block;
      }
      span {
        color: #999999;
        font-size: 12px;
        margin-left: 6px;
      }
    }
    .body-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      div.body-cards {
        width: 49%;
        border: 1px solid #f0d8a8;
        border-radius: 6px;
        overflow: hidden;
        h2 {
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #323232;
          background: #fbf3e5;
          padding: 6px 18px;
          box-sizing: border-box;
        }
        ul {
          widows: 100%;
          padding: 0 18px;
          padding-top: 12px;
          box-sizing: border-box;
          li {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #323232;
            margin-bottom: 12px;
            span {
              display: inline-block;
              margin-left: 12px;
              font-weight: 400;
              font-size: 10px !important;
              line-height: 18px;
              color: #7aa2f4;
              background: #eef3fd;
              border-radius: 2px;
              // width: 32px;
              height: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .detail-card-nopadding {
    padding: 18px 0 0;
    ::v-deep .information-limit {
      margin-bottom: 6px;
    }
    ::v-deep .btn {
      font-size: 12px !important;
    }
  }
  .footer {
    .storage_disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
::v-deep .el-form-item__label {
  color: #323232 !important;
}
::v-deep .el-input__inner {
  text-align: left !important;
  border-radius: 2px;
}

.cost-table {
  /deep/ .cell {
    height: auto !important;
  }
  /deep/ .el-form-item__error {
    position: relative;
    text-align: left;
  }
}
</style>
